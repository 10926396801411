import { useMemo } from 'react';
import { useAppSelector } from 'app/helpers';

export const useFiltersCount = (formName: string) => {
  const filtersForm = useAppSelector((state) => state.form[formName]?.values);

  const filtersCount = useMemo(() => {
    if (!filtersForm) return;
    const validFilters = Object.values(filtersForm).filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else if (value && typeof value === 'object') {
        return Object.keys(value).length > 0;
      }
      return Boolean(value);
    });
    return validFilters.length || undefined;
  }, [filtersForm]);

  return filtersCount;
};
