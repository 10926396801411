import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchBillingMedicationsState } from 'app/redux/branchBillingMedications/types';
import { Option } from 'app/types';

const initialState: BranchBillingMedicationsState = {
  selectedItemParams: null,
  expandedPerformer: null,
};

const branchBillingMedications = createSlice({
  name: 'branchBillingMedications',
  initialState,
  reducers: {
    setSelectedItemParams: (state, action: PayloadAction<{ branchId: string; medicationId: string } | null>) => {
      state.selectedItemParams = action.payload;
    },
    setExpandedPerformer: (state, action: PayloadAction<Option | null>) => {
      state.expandedPerformer = action.payload;
    },
  },
});

export const { setSelectedItemParams, setExpandedPerformer } = branchBillingMedications.actions;

export default branchBillingMedications.reducer;
