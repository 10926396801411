import React, { ForwardedRef } from 'react';
import { Input } from 'reactstrap';

interface Props extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean;
  inputId: string;
  inputPlaceholder?: string;
  invalid: boolean;
  bsSize?: 'lg' | 'sm';
}

export const CustomInput = React.forwardRef(
  ({ disabled, inputId, inputPlaceholder, invalid, bsSize, ...props }: Props, ref: ForwardedRef<Input>) => {
    return (
      <Input
        {...props}
        autoComplete="off"
        disabled={disabled}
        id={inputId}
        invalid={invalid}
        placeholder={inputPlaceholder}
        bsSize={bsSize}
        type="text"
        ref={ref}
      />
    );
  },
);
