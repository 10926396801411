import { AnyAction } from 'redux';
import { AccountBalanceSummary, Branch, RemoteData, SpecialityOption } from 'app/types';
import * as types from './account-balance.types';
import { generateInitialState } from './helpers';
import { PaginationParams } from './types';

interface AccountBalanceReducer {
  branches: RemoteData<Branch[], PaginationParams>;
  clinicsBalanceSummary: RemoteData<AccountBalanceSummary>;
  clinicsSummary: RemoteData<AccountBalanceSummary>;
  patientBalanceSummary: RemoteData<AccountBalanceSummary>;
  patientSummary: RemoteData<AccountBalanceSummary>;
  specialities: RemoteData<SpecialityOption[], PaginationParams>;
}

const initialState: AccountBalanceReducer = {
  clinicsSummary: {
    data: {} as AccountBalanceSummary,
    loading: false,
  },
  patientSummary: {
    data: {} as AccountBalanceSummary,
    loading: false,
  },
  clinicsBalanceSummary: {
    data: {} as AccountBalanceSummary,
    loading: false,
  },
  patientBalanceSummary: {
    data: {} as AccountBalanceSummary,
    loading: false,
  },
  branches: generateInitialState(),
  specialities: generateInitialState(),
};

export const accountBalanceReducer = (state = initialState, action: AnyAction): AccountBalanceReducer => {
  switch (action.type) {
    case types.FETCH_CLINICS_SUMMARY_REQUEST:
      return {
        ...state,
        patientSummary: {
          ...state.patientSummary,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_CLINICS_SUMMARY_SUCCESS:
      return {
        ...state,
        clinicsSummary: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_CLINICS_SUMMARY_FAILURE:
      return {
        ...state,
        clinicsSummary: {
          ...state.clinicsSummary,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_PATIENT_SUMMARY_REQUEST:
      return {
        ...state,
        patientSummary: {
          ...state.clinicsSummary,
          data: initialState.clinicsSummary.data,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_PATIENT_SUMMARY_SUCCESS:
      return {
        ...state,
        patientSummary: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_PATIENT_SUMMARY_FAILURE:
      return {
        ...state,
        patientSummary: {
          ...state.patientSummary,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_BRANCHES_REQUEST:
      return {
        ...state,
        branches: {
          ...initialState.branches,
          loading: true,
        },
      };
    case types.FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_BRANCHES_FAILURE:
      return {
        ...state,
        branches: {
          ...initialState.branches,
          loading: false,
        },
      };
    case types.FETCH_SPECIALITIES_REQUEST:
      return {
        ...state,
        specialities: {
          ...initialState.specialities,
          loading: true,
        },
      };
    case types.FETCH_SPECIALITIES_SUCCESS:
      return {
        ...state,
        specialities: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_SPECIALITIES_FAILURE:
      return {
        ...state,
        specialities: {
          ...initialState.specialities,
          loading: false,
        },
      };
    case types.FETCH_CLINICS_BALANCE_SUMMARY_REQUEST:
      return {
        ...state,
        clinicsBalanceSummary: {
          ...state.clinicsBalanceSummary,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_CLINICS_BALANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        clinicsBalanceSummary: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_CLINICS_BALANCE_SUMMARY_FAILURE:
      return {
        ...state,
        clinicsBalanceSummary: {
          ...state.clinicsBalanceSummary,
          loading: false,
          error: true,
        },
      };
    case types.FETCH_PATIENT_BALANCE_SUMMARY_REQUEST:
      return {
        ...state,
        patientBalanceSummary: {
          ...state.patientBalanceSummary,
          data: initialState.patientBalanceSummary.data,
          loading: true,
          error: false,
        },
      };
    case types.FETCH_PATIENT_BALANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        patientBalanceSummary: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };
    case types.FETCH_PATIENT_BALANCE_SUMMARY_FAILURE:
      return {
        ...state,
        patientBalanceSummary: {
          ...state.patientBalanceSummary,
          loading: false,
          error: true,
        },
      };

    case types.SET_PATIENT_BALANCE_SUMMARY_PARAMS:
      return {
        ...state,
        patientBalanceSummary: {
          ...state.patientBalanceSummary,
          params: {
            ...state.patientBalanceSummary.params,
            ...action.meta,
          },
        },
      };

    case types.SET_CLINIC_BALANCE_SUMMARY_PARAMS:
      return {
        ...state,
        clinicsBalanceSummary: {
          ...state.clinicsBalanceSummary,
          params: {
            ...state.clinicsBalanceSummary.params,
            ...action.meta,
          },
        },
      };
    default:
      return state;
  }
};
