import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { calculatePackageActions } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { CreditNoteConfiguration, CreditNotePackageAction } from 'app/redux/invoicingProcess/types';
import { InvoiceCreditNoteAction, InvoiceCreditNotePackageAction } from 'app/types';
import { creditNoteBodyAdapter } from 'app/features/invoicingProcess/process/actions/helpers/creditNoteBodyAdapter';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { DeclarationFormData } from 'app/features/invoicingProcess/process/items/declarationForm/types';
import { PaymentConfigurationFormData } from 'app/features/invoicingProcess/process/paymentMethod/paymentConfigurationForm/types';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const useOnCalculatePackageActions = (selectedPackageAction: InvoiceCreditNotePackageAction | undefined) => {
  const dispatch = useAppDispatch();
  const { step, mode } = useInvoicingProcessState();
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const paymentModeValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values) as
    | PaymentModeFormData
    | undefined;
  const paymentConfigurationValues = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG]?.values,
  ) as PaymentConfigurationFormData | undefined;
  const declarationValues = useAppSelector((state) => state.form[FORMS_INVOICING_PROCESS_DECLARATION]?.values) as
    | DeclarationFormData
    | undefined;
  const creditNoteConfiguration = useAppSelector((state) => state.invoicingProcess.creditNoteConfiguration);
  const calculatePackageActionsLoading = useAppSelector(
    (state) => state.invoicingProcess.creditNotePackageActions.loading,
  );
  const calculatedPackageActions = useAppSelector((state) => state.invoicingProcess.creditNotePackageActions.data);

  const conflictedPackageAction = useMemo<CreditNotePackageAction | undefined>(() => {
    return calculatedPackageActions.find((ca) => !ca.isAllowed && ca.action.value === selectedPackageAction);
  }, [calculatedPackageActions, selectedPackageAction]);

  const onCalculatePackageActions = useCallback(
    (customCreditNoteConfiguration?: CreditNoteConfiguration) => {
      const configuration = customCreditNoteConfiguration || creditNoteConfiguration;
      const isCompleteRefund = configuration?.action === InvoiceCreditNoteAction.Refund;
      const invoiceId = invoicingProcess?.invoiceId;

      if (step && mode && invoiceId && paymentModeValues && paymentConfigurationValues && configuration) {
        const invoiceType = invoicingProcess.invoiceType?.value;
        const isPrimaryCareSpeciality = invoicingProcess.doctor.details?.isPrimaryCareSpeciality;

        const body = creditNoteBodyAdapter({
          creditNoteConfiguration: configuration,
          declarationValues,
          invoiceId,
          invoiceType,
          isPrimaryCareSpeciality,
          items,
          paymentConfigurationValues,
          paymentMethodItems,
          paymentModeValues,
        });

        return dispatch(calculatePackageActions(body, isCompleteRefund));
      }
    },
    [
      creditNoteConfiguration,
      declarationValues,
      dispatch,
      invoicingProcess,
      items,
      mode,
      paymentConfigurationValues,
      paymentMethodItems,
      paymentModeValues,
      step,
    ],
  );

  return {
    calculatePackageActionsLoading,
    calculatedPackageActions,
    conflictedPackageAction,
    onCalculatePackageActions,
  };
};
