import { generatePath } from 'react-router';
import { clinicBranchesPath, doctorsLogsPath } from 'app/routes/clinics/constants';
import { BranchesTab, DoctorLogsTab } from 'app/routes/clinics/types';

export const generateClinicBranchesPath = (tab: BranchesTab, clinicId: string) =>
  generatePath(clinicBranchesPath, {
    clinicId,
    tab,
  });

export const generateDoctorLogsPath = (tab: DoctorLogsTab, doctorId: string, clinicId: string) =>
  generatePath(doctorsLogsPath, {
    clinicId,
    doctorId,
    tab,
  });
