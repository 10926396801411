import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AssignMedications,
  ExportPractitionerMedicationsParams,
  MedicationBody,
} from 'app/services/billingItemsMedications/types';
import {
  Medication,
  MedicationItem,
  Option,
  PerformerMedication,
  PerformerMedicationItem,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';

export const billingItemsMedicationsApi = createApi({
  reducerPath: 'billingItemsMedicationsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Medications', 'Medication', 'PerformerMedications', 'Performers'],
  endpoints: (builder) => ({
    // Branch Medications
    getBillingItemsMedications: builder.query<
      QueryResponseWithPagination<MedicationItem[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-medications`,
        method: 'GET',
        params,
      }),
      providesTags: ['Medications'],
    }),
    getBillingItemsMedication: builder.query<QueryResponse<Medication>, { branchId: string; medicationId: string }>({
      query: ({ branchId, medicationId }) => ({
        url: `branches/${branchId}/branch-billing-medications/${medicationId}`,
        method: 'GET',
      }),
      providesTags: ['Medication'],
    }),
    addBillingItemMedication: builder.mutation<void, { branchId: string; body: MedicationBody }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/branch-billing-medications`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Medications'],
    }),
    editBillingItemMedication: builder.mutation<void, { branchId: string; medicationId: string; body: MedicationBody }>(
      {
        query: ({ branchId, medicationId, body }) => ({
          url: `branches/${branchId}/branch-billing-medications/${medicationId}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['Medications', 'Medication'],
      },
    ),
    removeBillingItemMedication: builder.mutation<void, { branchId: string; medicationId: string }>({
      query: ({ branchId, medicationId }) => ({
        url: `branches/${branchId}/branch-billing-medications/${medicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Medications'],
    }),
    toggleBillingItemMedication: builder.mutation<void, { branchId: string; medicationId: string; enabled: boolean }>({
      query: ({ branchId, medicationId, enabled }) => ({
        url: `branches/${branchId}/branch-billing-medications/${medicationId}/enabled`,
        method: 'PUT',
        params: { enabled },
      }),
      invalidatesTags: ['Medications', 'Medication'],
    }),
    exportBillingItemsMedications: builder.query<void, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-medications/excel`,
        method: 'GET',
        params,
      }),
    }),

    // Performer Medications
    getPerformers: builder.query<QueryResponse<Option[]>, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `branch/${branchId}/billing-medications/performers`,
        method: 'GET',
        params,
      }),
      providesTags: ['Performers'],
    }),
    getPerformerBillingItemsMedications: builder.query<
      QueryResponseWithPagination<PerformerMedicationItem[]>,
      { branchId: string; performerId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, performerId, params }) => ({
        url: `branches/${branchId}/performers/${performerId}/performer-billing-medications`,
        method: 'GET',
        params,
      }),
      providesTags: ['PerformerMedications'],
    }),
    getPerformerBillingItemsMedication: builder.query<
      QueryResponse<PerformerMedication>,
      { branchId: string; medicationId: string }
    >({
      query: ({ branchId, medicationId }) => ({
        url: `branches/${branchId}/performer-billing-medications/${medicationId}`,
        method: 'GET',
      }),
    }),
    assignBillingItemMedicationToPerformers: builder.mutation<void, { branchId: string; body: AssignMedications }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/performer-billing-medications`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PerformerMedications'],
    }),
    removePerformerBillingItemMedication: builder.mutation<void, { branchId: string; medicationId: string }>({
      query: ({ branchId, medicationId }) => ({
        url: `branches/${branchId}/performer-billing-medications/${medicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PerformerMedications'],
    }),
    togglePerformerBillingItemMedication: builder.mutation<
      void,
      { branchId: string; medicationId: string; enabled: boolean }
    >({
      query: ({ branchId, medicationId, enabled }) => ({
        url: `branches/${branchId}/performer-billing-medications/${medicationId}/enabled`,
        method: 'PUT',
        params: { enabled },
      }),
      invalidatesTags: ['PerformerMedications'],
    }),
    exportPerformerBillingItemsMedications: builder.query<
      void,
      { branchId: string; params: ExportPractitionerMedicationsParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/performer-billing-medications/excel`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useAddBillingItemMedicationMutation,
  useAssignBillingItemMedicationToPerformersMutation,
  useGetBillingItemsMedicationQuery,
  useLazyGetBillingItemsMedicationQuery,
  useGetBillingItemsMedicationsQuery,
  useGetPerformerBillingItemsMedicationQuery,
  useGetPerformerBillingItemsMedicationsQuery,
  useGetPerformersQuery,
  useEditBillingItemMedicationMutation,
  useLazyExportBillingItemsMedicationsQuery,
  useLazyExportPerformerBillingItemsMedicationsQuery,
  useRemoveBillingItemMedicationMutation,
  useRemovePerformerBillingItemMedicationMutation,
  useToggleBillingItemMedicationMutation,
  useTogglePerformerBillingItemMedicationMutation,
} = billingItemsMedicationsApi;
