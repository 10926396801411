import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import { useAddClinicPatientInsuranceDetailsMutation } from 'app/services/patients/patients';
import { ErrorWithMessage } from 'app/types';
import { useOnInsuranceCompanyUpdate } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/helpers/useOnInsuranceCompanyUpdate';
import { formValuesAdapter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/formValuesAdapter';
import { PatientInsuranceForm } from 'app/features/patient/patientInsuranceDetailsForm/patientInsuranceForm';
import { PatientInsuranceFormData } from 'app/features/patient/patientInsuranceDetailsForm/types';

interface Props {
  branchId?: string;
  clinicId?: string;
  isOpen: boolean;
  patientId: string;
  toggle: () => void;
}

export const AddMedicalInsuranceModal = ({ branchId, clinicId, isOpen, patientId, toggle }: Props) => {
  const onInsuranceCompanyUpdate = useOnInsuranceCompanyUpdate();
  const [addInsuranceDetails] = useAddClinicPatientInsuranceDetailsMutation();

  const initialValues = useMemo(() => {
    if (branchId && clinicId) {
      // Take initial values from predefined params (options are not rendered)
      return {
        clinic: { label: '', value: clinicId },
        clinicBranch: { label: '', value: branchId },
      };
    }

    return undefined;
  }, [branchId, clinicId]);

  const onSubmit = useCallback(
    async (values: PatientInsuranceFormData) => {
      try {
        await addInsuranceDetails({ patientId, body: formValuesAdapter(values) }).unwrap();
        toast.success('CORE.TEXT.ITEM-ADDED-SUCCESSFULLY');
        onInsuranceCompanyUpdate();
        toggle();
      } catch (error) {
        const queryError = error as ErrorWithMessage;
        if (queryError?.data?.message) {
          toast.error(queryError.data.message);
        } else {
          toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
        }
      }
    },
    [addInsuranceDetails, onInsuranceCompanyUpdate, patientId, toggle],
  );

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={isOpen} toggle={toggle}>
      <PatientInsuranceForm
        branchId={branchId}
        clinicId={clinicId}
        closeModal={toggle}
        headerText={'INSURANCES.TEXT.ADD-INSURANCE'}
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitText={'CORE.BUTTON.SUBMIT'}
      />
    </Modal>
  );
};
