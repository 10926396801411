import React, { useCallback } from 'react';
import delay from 'lodash/delay';
import { DatePickerProps } from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import { FormFeedback, FormGroup } from 'reactstrap';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { DatePicker } from 'app/components';
import { CustomLabel } from 'app/components/customLabel/customLabel';
import { CustomInput } from './customInput/customInput';

export type DatePickerComponentProps = DatePickerProps & {
  inactive?: boolean;
  isRequired?: boolean;
  label: string;
  placeholder?: string;
  size?: 'lg' | 'sm';
  info?: string;
};

export const DatePickerComponent = ({
  dateFormat = 'dd/MM/yyyy',
  input,
  isRequired,
  label,
  meta,
  placeholder,
  onCalendarOpen,
  info,
  ...restProps
}: WrappedFieldProps & DatePickerComponentProps) => {
  const inputId = restProps.id || input.name;
  const invalid = !!meta.error && meta.touched;

  const handleOpen = useCallback(() => {
    if (onCalendarOpen) {
      onCalendarOpen();
    }

    delay(() => {
      const selectedItem = document.querySelector<HTMLElement>('.react-datepicker__time-list-item--selected');
      const parent = document.querySelector<HTMLElement>('.react-datepicker__time-list');

      if (selectedItem && parent) {
        const valueToCenter = parent.offsetWidth / 2 - selectedItem.offsetWidth / 2;
        parent.scrollTo({
          left: selectedItem.offsetLeft - valueToCenter,
          behavior: 'smooth',
        });
      }
    }, 100);
  }, [onCalendarOpen]);

  return (
    <FormGroup>
      {label && <CustomLabel fieldId={inputId} info={info} isRequired={isRequired} label={label} />}
      <DatePicker
        customInput={
          <CustomInput
            disabled={restProps.disabled}
            inputId={inputId}
            inputPlaceholder={placeholder}
            invalid={invalid}
            bsSize={restProps.size}
          />
        }
        dateFormat={dateFormat}
        isClearable
        selected={input.value || null}
        {...restProps}
        onChange={input.onChange}
        onCalendarOpen={handleOpen}
      />
      {invalid && (
        <FormFeedback className="d-block">
          <FormattedMessage id={meta.error} />
        </FormFeedback>
      )}
    </FormGroup>
  );
};
