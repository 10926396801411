import isEmpty from 'lodash/isEmpty';
import { initialize as initializeForm } from 'redux-form';
import { put } from 'redux-saga/effects';
import {
  FORMS_INVOICING_PROCESS_DECLARATION,
  FORMS_INVOICING_PROCESS_DETAILS,
  FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG,
  FORMS_INVOICING_PROCESS_PAYMENT_MODE,
} from 'app/shared';
import { overwriteItems } from 'app/redux/invoicingProcess/helpers';
import {
  initialize as initializeItems,
  setAppointment,
  setInvoicePackageSnapshotId,
  setStep,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcess, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { Locale } from 'app/types';
import { checkIsInsuranceApprovalRequestBased } from 'app/features/invoicingProcess/helpers/checkIsInsuranceApprovalRequestBased';
import {
  getDeclarationInitialValues,
  getDetailsInitialValues,
  getPaymentConfigurationInitialValues,
  getPaymentModeInitialValues,
} from 'app/features/invoicingProcess/process/helpers';
import { validate as validatePaymentModeForm } from 'app/features/invoicingProcess/process/paymentMode/form/helpers/validate';

type Configuration = Partial<{
  locale: Locale;
  withStepDetection: boolean;
}>;

export function* initializeInvoicingProcess(data: InvoicingProcess, configuration?: Configuration) {
  const invoicingProcess = overwriteItems(data);
  const items = invoicingProcess.steps?.items || [];
  const paymentMethodItems = invoicingProcess.steps?.paymentMethod?.items || [];
  const paymentModeInitialValues = getPaymentModeInitialValues(invoicingProcess);

  // Set appointment ID (if appointment is assigned to process)
  if (invoicingProcess.appointment) {
    yield put<any>(setAppointment(invoicingProcess.appointment.id));
  }

  // Initialize details (if appointment is not assigned to process)
  if (!invoicingProcess.appointment) {
    yield put<any>(
      initializeForm(FORMS_INVOICING_PROCESS_DETAILS, getDetailsInitialValues(invoicingProcess, configuration?.locale)),
    );
  }

  // Set invoice package snapshot ID (if process was created with package items)
  if (invoicingProcess.invoicePackageSnapshotId) {
    yield put<any>(setInvoicePackageSnapshotId(invoicingProcess.invoicePackageSnapshotId));
  }

  // Initialize invoicing process items
  yield put<any>(initializeItems({ items, paymentMethodItems, data: invoicingProcess }));

  // Initialize payment mode form
  yield put<any>(initializeForm(FORMS_INVOICING_PROCESS_PAYMENT_MODE, paymentModeInitialValues));

  // Initialize payment configuration form
  yield put<any>(
    initializeForm(FORMS_INVOICING_PROCESS_PAYMENT_METHOD_CFG, getPaymentConfigurationInitialValues(invoicingProcess)),
  );

  // Initialize declaration form
  yield put<any>(initializeForm(FORMS_INVOICING_PROCESS_DECLARATION, getDeclarationInitialValues(invoicingProcess)));

  // Select step (if detection is active)
  if (configuration?.withStepDetection) {
    if (!invoicingProcess.isDraft) {
      yield put<any>(setStep(InvoicingProcessStep.Summary));
      return;
    }

    if (!checkIsInsuranceApprovalRequestBased(invoicingProcess)) {
      // For the insurance approval request-based process Payment Mode step is not completed

      // Variables
      const isPaymentModeValid =
        paymentModeInitialValues &&
        isEmpty(
          validatePaymentModeForm(paymentModeInitialValues, {
            appointmentId: invoicingProcess.appointment?.id || null,
            invoicingProcess,
          }),
        );

      if (!isPaymentModeValid) {
        // Change step to Payment Mode if initial values are not valid
        yield put<any>(setStep(InvoicingProcessStep.PaymentMode));
        return;
      }

      if (!!items.length) {
        // Change step to Payment Method if items already exist
        yield put<any>(setStep(InvoicingProcessStep.PaymentMethod));
        return;
      }

      if (invoicingProcess.steps?.paymentMode) {
        // Change step to Items if Payment Mode step has not been started
        yield put<any>(setStep(InvoicingProcessStep.Items));
        return;
      }
    }
  }
}
