import { useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { InvoicePayBy } from 'app/types';

export const usePayByValue = () => {
  const payByValue: InvoicePayBy | undefined = useAppSelector(
    (state) => state.form[FORMS_INVOICING_PROCESS_PAYMENT_MODE]?.values?.payBy?.value,
  );

  return payByValue;
};
