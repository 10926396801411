import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Alert } from 'reactstrap';
import { useAppIntl } from 'app/helpers';
import { FormattedPrice } from 'app/components';
import { LocalBootstrapTable } from 'app/components/bootstrapTable/localBootstrapTable/localBootstrapTable';
import { CreditNotePackageAction, CreditNotePackageActionBillingItem } from 'app/redux/invoicingProcess/types';
import { Locale } from 'app/types';

interface Props {
  packageAction: CreditNotePackageAction;
}
export const ConflictedPackageAction = ({ packageAction }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  const columns = useMemo(
    (): ColumnDescription<CreditNotePackageActionBillingItem>[] => [
      {
        dataField: 'internalCode',
        formatter: (_, row) => row.internalCode || '-',
        text: formatMessage({ id: 'BILLING-ITEMS.LABEL.INTERNAL-CODE' }),
      },
      {
        dataField: 'name',
        formatter: (_, row) => (locale === Locale.ar ? row.name.ar : row.name.en),
        text: formatMessage({ id: 'CORE.LABEL.NAME' }),
      },
      {
        dataField: 'sellingPrice',
        formatter: (_, row) => <FormattedPrice amount={row.sellingPrice} />,
        text: formatMessage({ id: 'BILLING-ITEMS.LABEL.SELLING-PRICE' }),
      },
      {
        classes: 'text-danger',
        dataField: 'minimumSellingPrice',
        formatter: (_, row) => <FormattedPrice amount={row.minimumSellingPrice} />,
        text: formatMessage({ id: 'CORE.LABEL.MINIMUM-SELLING-PRICE' }),
      },
    ],
    [formatMessage, locale],
  );

  return (
    <div className="mb-3">
      <Alert color="danger small">{formatMessage({ id: 'CORE.TEXT.PACKAGE-ACTION-ITEMS-PRICE-WARNING' })}</Alert>
      <LocalBootstrapTable
        classes="table-sm"
        columns={columns}
        data={packageAction.blockingPerformerBillingItems}
        id="blockingPerformerBillingItems"
        keyField="id"
        loading={false}
        noDataComponent={<></>}
      />
    </div>
  );
};
