import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl, useDictionaries } from 'app/helpers';
import { required } from 'app/shared';
import { ChipsField, ChipsFieldProps } from 'app/shared/form-elements/chips/chipsField';
import {
  CreditNotePackageActionsField,
  CreditNotePackageActionsFormData,
} from 'app/features/invoicingProcess/modals/creditNotePackageActionsModal/creditNotePackageActionsForm/types';

const FormComponent = ({ handleSubmit }: InjectedFormProps<CreditNotePackageActionsFormData>) => {
  const { formatMessage } = useAppIntl();
  const { 'package-actions-after-credit-note': packageActionOptions } = useDictionaries();

  return (
    <Form onSubmit={handleSubmit}>
      {packageActionOptions && (
        <Field<BaseFieldProps<ChipsFieldProps>>
          name={CreditNotePackageActionsField.packageAction}
          component={ChipsField}
          props={{
            chips: packageActionOptions,
            isRequired: true,
            label: formatMessage({ id: 'CORE.TEXT.WHAT-TO-DO-AFTER-RETURNING-PACKAGE-ITEMS' }),
          }}
          validate={[required]}
        />
      )}
    </Form>
  );
};

export const CreditNotePackageActionsForm = reduxForm<CreditNotePackageActionsFormData>({})(FormComponent);
