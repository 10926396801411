import { AnyAction } from 'redux';
import {
  addInvoiceItem,
  addPaymentMethod,
  deleteInvoiceItem,
  deletePaymentMethod,
  editInvoiceItem,
  editPaymentMethod,
  overwriteItems,
} from 'app/redux/invoicingProcess/helpers';
import * as types from 'app/redux/invoicingProcess/invoicingProcess.types';
import { InvoicingProcessReducer } from 'app/redux/invoicingProcess/types';

const initialState: InvoicingProcessReducer = {
  appointmentId: null,
  createCreditNoteLoading: false,
  createLoading: false,
  creditNoteConfiguration: null,
  data: null,
  insuranceCompanyCacheUniq: null,
  insuranceApprovalItems: {
    data: [],
    loading: false,
  },
  insuranceReferralItems: {
    data: [],
    loading: false,
  },
  creditNotePackageActions: {
    data: [],
    loading: false,
  },
  items: [],
  loading: false,
  mode: null,
  paymentMethodItems: [],
  invoicePackageSnapshotId: null,
  requestId: null,
  saveAsDraftLoading: false,
  selectedItem: null,
  selectedPaymentMethodItem: null,
  step: null,
  updatePaymentsLoading: false,
};

export const invoicingProcessReducer = (state = initialState, action: AnyAction): InvoicingProcessReducer => {
  switch (action.type) {
    case types.INITIALIZE:
      return {
        ...state,
        ...action.payload,
      };
    case types.RESET:
      return {
        ...initialState,
        ...(action.payload?.keepMode && { mode: state.mode }),
        ...(action.payload?.keepStep && { step: state.step }),
      };
    case types.SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case types.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case types.SET_APPOINTMENT:
      return {
        ...state,
        appointmentId: action.payload,
      };
    case types.SET_REQUEST:
      return {
        ...state,
        requestId: action.payload,
      };
    case types.SET_INVOICE_PACKAGE_SNAPSHOT_ID:
      return {
        ...state,
        invoicePackageSnapshotId: action.payload,
      };
    case types.ADD_INVOICE_ITEM:
      return {
        ...state,
        items: addInvoiceItem(state.items, action.payload),
      };
    case types.EDIT_INVOICE_ITEM:
      return {
        ...state,
        items: editInvoiceItem(state.items, action.payload),
      };
    case types.DELETE_INVOICE_ITEM:
      return {
        ...state,
        items: deleteInvoiceItem(state.items, action.payload),
      };
    case types.ADD_PAYMENT_METHOD_ITEM:
      return {
        ...state,
        paymentMethodItems: addPaymentMethod(state.paymentMethodItems, action.payload),
      };
    case types.EDIT_PAYMENT_METHOD_ITEM:
      return {
        ...state,
        paymentMethodItems: editPaymentMethod(state.paymentMethodItems, action.payload),
      };
    case types.DELETE_PAYMENT_METHOD_ITEM:
      return {
        ...state,
        paymentMethodItems: deletePaymentMethod(state.paymentMethodItems, action.payload),
      };
    case types.SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case types.RESET_INVOICE_ITEMS:
      return {
        ...state,
        items: initialState.items,
      };
    case types.SELECT_PAYMENT_METHOD_ITEM:
      return {
        ...state,
        selectedPaymentMethodItem: action.payload,
      };
    case types.FETCH_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_INVOICE_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DRAFT_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_DRAFT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_EMPTY_WITH_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_EMPTY_WITH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_EMPTY_WITH_APPOINTMENT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_EMPTY_WITHOUT_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_EMPTY_WITHOUT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_EMPTY_WITHOUT_APPOINTMENT_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_EMPTY_BY_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_EMPTY_BY_REQUEST_SUCCESS:
      return {
        ...state,
        data: overwriteItems(action.payload.data),
        loading: false,
      };
    case types.FETCH_EMPTY_BY_REQUEST_FAILURE:
      return {
        ...state,
        data: initialState.data,
        loading: false,
      };
    case types.FETCH_INSURANCE_REFERRAL_ITEMS_REQUEST:
      return {
        ...state,
        insuranceReferralItems: {
          ...state.insuranceReferralItems,
          loading: true,
        },
      };
    case types.FETCH_INSURANCE_REFERRAL_ITEMS_SUCCESS:
      return {
        ...state,
        insuranceReferralItems: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_INSURANCE_REFERRAL_ITEMS_FAILURE:
      return {
        ...state,
        insuranceReferralItems: {
          data: [],
          loading: false,
        },
      };
    case types.CALCULATE_PACKAGE_ACTIONS_REQUEST:
      return {
        ...state,
        creditNotePackageActions: {
          ...state.creditNotePackageActions,
          loading: true,
        },
      };
    case types.CALCULATE_PACKAGE_ACTIONS_SUCCESS:
      return {
        ...state,
        creditNotePackageActions: {
          data: action.payload,
          loading: false,
        },
      };
    case types.CALCULATE_PACKAGE_ACTIONS_FAILURE:
      return {
        ...state,
        creditNotePackageActions: {
          data: [],
          loading: false,
        },
      };
    case types.FETCH_INSURANCE_APPROVAL_ITEMS_REQUEST:
      return {
        ...state,
        insuranceApprovalItems: {
          ...state.insuranceApprovalItems,
          loading: true,
        },
      };
    case types.FETCH_INSURANCE_APPROVAL_ITEMS_SUCCESS:
      return {
        ...state,
        insuranceApprovalItems: {
          data: action.payload.data,
          loading: false,
        },
      };
    case types.FETCH_INSURANCE_APPROVAL_ITEMS_FAILURE:
      return {
        ...state,
        insuranceApprovalItems: {
          data: [],
          loading: false,
        },
      };
    case types.CREATE_WITH_APPOINTMENT_REQUEST:
      return {
        ...state,
        createLoading: true,
      };
    case types.CREATE_WITH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        createLoading: false,
      };
    case types.CREATE_WITH_APPOINTMENT_FAILURE:
      return {
        ...state,
        createLoading: false,
      };
    case types.CREATE_WITHOUT_APPOINTMENT_REQUEST:
      return {
        ...state,
        createLoading: true,
      };
    case types.CREATE_WITHOUT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        createLoading: false,
      };
    case types.CREATE_WITHOUT_APPOINTMENT_FAILURE:
      return {
        ...state,
        createLoading: false,
      };
    case types.CREATE_DRAFT_WITH_APPOINTMENT_REQUEST:
      return {
        ...state,
        saveAsDraftLoading: true,
      };
    case types.CREATE_DRAFT_WITH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        saveAsDraftLoading: false,
      };
    case types.CREATE_DRAFT_WITH_APPOINTMENT_FAILURE:
      return {
        ...state,
        saveAsDraftLoading: false,
      };
    case types.CREATE_DRAFT_WITHOUT_APPOINTMENT_REQUEST:
      return {
        ...state,
        saveAsDraftLoading: true,
      };
    case types.CREATE_DRAFT_WITHOUT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        saveAsDraftLoading: false,
      };
    case types.CREATE_DRAFT_WITHOUT_APPOINTMENT_FAILURE:
      return {
        ...state,
        saveAsDraftLoading: false,
      };
    case types.CREATE_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        createCreditNoteLoading: true,
      };
    case types.CREATE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        createCreditNoteLoading: false,
      };
    case types.CREATE_CREDIT_NOTE_FAILURE:
      return {
        ...state,
        createCreditNoteLoading: false,
      };
    case types.UPDATE_PAYMENTS_REQUEST:
      return {
        ...state,
        updatePaymentsLoading: true,
      };
    case types.UPDATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        updatePaymentsLoading: false,
      };
    case types.UPDATE_PAYMENTS_FAILURE:
      return {
        ...state,
        updatePaymentsLoading: false,
      };
    case types.SET_CREDIT_NOTE_CONFIGURATION:
      return {
        ...state,
        creditNoteConfiguration: action.payload,
      };
    case types.SET_INSURANCE_COMPANY_CACHE_UNIQ:
      return {
        ...state,
        insuranceCompanyCacheUniq: action.payload,
      };
    default:
      return state;
  }
};
