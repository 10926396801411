import { Doctor } from 'app/types/Doctor';
import { Option } from 'app/types/Option';

export enum UserLogObjectType {
  Undefined = '0',
  Appointment = '1',
  BranchWorkingTime = '2',
  BusyTime = '3',
  ClinicBranch = '4',
  Clinic = '5',
  CustomWorkingTime = '6',
  Dependant = '7',
  Doctor = '8',
  DoctorSubSpeciality = '9',
  InsuranceCompany = '10',
  Patient = '11',
  Speciality = '12',
  SubSpeciality = '13',
  User = '14',
  WaitingAppointment = '15',
  WorkingTime = '16',
  Export = '17',
  BranchBillingItem = '18',
  MedicalAttachment = '19',
  Prescription = '20',
  PatientAllergy = '21',
  InitialAssessment = '22',
  PatientArrival = '23',
  DoctorAssessment = '24',
  Referral = '25',
  InsuranceApproval = '26',
  BranchBillingItemMedication = '27',
  PerformerBillingItemMedication = '28',
  BranchBillingPackage = '29',
  PerformerBillingPackage = '30',
  ReferralInsuranceApproval = '31',
  EmergencyNote = '32',
  EmergencyContact = '33',
  ClinicPatient = '34',
  InsuranceClaim = '35',
  InsuranceClaimStatement = '36',
  PerformerBillingItem = '37',
  InvoiceRequest = '38',
  Invoice = '39',
  DraftInvoice = '40',
  CreditNote = '41',
  InsuranceClaimSettlementFile = '42',
  BranchBillingItemMassAdding = '43',
}

export type UserLog = {
  action: Option;
  branch?: Option;
  dateOfAction: string;
  objectId: string;
  objectName: Option;
  objectType: Option<UserLogObjectType>;
  doctor?: UserLogDoctorInfo;
  actionTaker?: Option;
};

export type UserLogDoctorInfo = Pick<
  Doctor,
  'firstNameEn' | 'lastNameEn' | 'firstNameAr' | 'lastNameAr' | 'title' | 'id'
>;
