import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import { useEditPatientInsuranceDetailsMutation } from 'app/services/patients/patients';
import { ErrorWithMessage, InsuranceDetailsItem } from 'app/types';
import { useOnInsuranceCompanyUpdate } from 'app/features/patient/medicalInstitutionPatientProfile/insuranceDetails/helpers/useOnInsuranceCompanyUpdate';
import { formEditValuesAdapter } from 'app/features/patient/patientInsuranceDetailsForm/helpers/formEditValuesAdapter';
import { useInitialValues } from 'app/features/patient/patientInsuranceDetailsForm/helpers/useInitialValues';
import { PatientInsuranceForm } from 'app/features/patient/patientInsuranceDetailsForm/patientInsuranceForm';
import { PatientInsuranceFormData } from 'app/features/patient/patientInsuranceDetailsForm/types';

interface Props {
  branchId?: string;
  clinicId?: string;
  isOpen: boolean;
  patientId: string;
  patientInsuranceDetails?: InsuranceDetailsItem | null;
  toggle: () => void;
}

export const EditMedicalInsuranceModal = ({
  branchId,
  clinicId,
  isOpen,
  patientId,
  toggle,
  patientInsuranceDetails,
}: Props) => {
  const onInsuranceCompanyUpdate = useOnInsuranceCompanyUpdate();
  const [eddInsuranceDetails] = useEditPatientInsuranceDetailsMutation();
  const initialValues = useInitialValues(patientInsuranceDetails);

  const onSubmit = useCallback(
    async (values: PatientInsuranceFormData) => {
      if (patientInsuranceDetails)
        try {
          await eddInsuranceDetails({
            patientId,
            insuranceId: patientInsuranceDetails.id,
            body: formEditValuesAdapter(values),
          }).unwrap();
          toast.success('CORE.TEXT.ITEM-CHANGED-SUCCESSFULLY');
          onInsuranceCompanyUpdate();
          toggle();
        } catch (error) {
          const queryError = error as ErrorWithMessage;
          if (queryError?.data?.message) {
            toast.error(queryError.data.message);
          } else {
            toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
          }
        }
    },
    [eddInsuranceDetails, onInsuranceCompanyUpdate, patientId, patientInsuranceDetails, toggle],
  );

  return (
    <Modal backdrop="static" keyboard={false} size="lg" isOpen={isOpen} toggle={toggle}>
      <PatientInsuranceForm
        branchId={branchId}
        clinicId={clinicId}
        closeModal={toggle}
        headerText={'INSURANCES.TEXT.EDIT-INSURANCE-COMPANY'}
        submitText={'CORE.BUTTON.SAVE-CHANGES'}
        onSubmit={onSubmit}
        initialValues={initialValues}
        isEdit={true}
      />
    </Modal>
  );
};
