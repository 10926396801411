import { Dispatch } from 'redux';
import { change } from 'redux-form';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { ErrorType, InvoiceItemVat } from 'app/types';
import { getPriceAfterDiscount } from 'app/features/invoicingProcess/process/items/helpers';
import { ItemFormProps } from 'app/features/invoicingProcess/process/items/itemForm/itemForm';
import { ItemField, ItemFormData } from 'app/features/invoicingProcess/process/items/itemForm/types';

export const onChange = (
  values: Partial<ItemFormData>,
  dispatch: Dispatch,
  props: DecoratedFormProps<ItemFormData, ItemFormProps, ErrorType>,
  previousValues: Partial<ItemFormData>,
) => {
  if (props.form) {
    // #1. Sync Total Price
    if (values.item && values.quantity) {
      const discountValue = Number(values?.discountValue || 0);
      const newTotalPrice =
        values && values.discountType
          ? getPriceAfterDiscount({
              discount: {
                discountTypeView: { label: '', value: values.discountType }, // label is not required
                value: discountValue < 0 ? 0 : discountValue,
              },
              price: values.item.details.price || 0,
              quantity: values.quantity,
              vat: InvoiceItemVat.Default,
            })
          : 0;

      if (previousValues.totalPrice !== newTotalPrice) {
        if (newTotalPrice <= 0) {
          dispatch(change(props.form, ItemField.totalPrice, 0));
        } else {
          dispatch(change(props.form, ItemField.totalPrice, newTotalPrice));
        }
      }
    } else if (previousValues.totalPrice !== 0) {
      dispatch(change(props.form, ItemField.totalPrice, 0));
    }
  }
};
