import { ChangeEvent, useCallback, useMemo } from 'react';
import { Col, Fade, Row } from 'reactstrap';
import { BaseFieldProps, Field, Form, reduxForm } from 'redux-form';
import { EventWithDataHandler } from 'redux-form/lib/Field';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl, useAppSelector, useDictionaries } from 'app/helpers';
import { InputComponent, Loader, required } from 'app/shared';
import { ChipsField, ChipsFieldProps } from 'app/shared/form-elements/chips/chipsField';
import { ErrorType, InvoiceCreditNoteAction, InvoiceCreditNoteReason, Option } from 'app/types';
import {
  CreditNoteField,
  CreditNoteFormData,
} from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteForm/types';
import { checkIsOldInvoice, useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';

export interface CreditNoteFormProps {}

const FormComponent = ({
  change,
  form,
  handleSubmit,
}: InjectedFormProps<CreditNoteFormData, CreditNoteFormProps, ErrorType>) => {
  const { formatMessage } = useAppIntl();
  const { 'package-actions-after-credit-note': packageActionOptions, 'credit-note-reasons': creditNoteReasons } =
    useDictionaries();
  const { invoicePackageSnapshotId } = useInvoicingProcessState();
  const calculatePackageActionsLoading = useAppSelector(
    (state) => state.invoicingProcess.creditNotePackageActions.loading,
  );
  const invoiceType = useAppSelector((state) => state.invoicingProcess.data?.invoiceType?.value);
  const hasUsedPackageItems = useAppSelector((state) => !!state.invoicingProcess.data?.hasUsedPackageItems);
  const isOldInvoice = checkIsOldInvoice(invoiceType);
  const action: CreditNoteFormData['action'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[CreditNoteField.action],
  );
  const reason: CreditNoteFormData['reason'] | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[CreditNoteField.reason],
  );
  const isRefund = action === InvoiceCreditNoteAction.Refund;
  const isOtherReason = reason === InvoiceCreditNoteReason.Other;

  const reasonOptions = useMemo<Option<InvoiceCreditNoteReason>[] | undefined>(() => {
    const hiddenOptions = [InvoiceCreditNoteReason.ExceededPreAuthLimit];

    if (!invoicePackageSnapshotId) {
      hiddenOptions.push(InvoiceCreditNoteReason.ReturnPackageItem);
    }

    return creditNoteReasons?.filter((r) => !hiddenOptions.includes(r.value));
  }, [creditNoteReasons, invoicePackageSnapshotId]);

  const canShowPackageActions = isRefund && reason && hasUsedPackageItems && packageActionOptions;

  const actionOptions = useMemo<Option<InvoiceCreditNoteAction>[]>(
    () => [
      ...(!isOldInvoice
        ? [
            {
              label: formatMessage({
                id: 'CORE.BUTTON.MODIFY-WITH-CREDIT-NOTE',
                defaultMessage: 'Modify with a Credit Note',
              }),
              value: InvoiceCreditNoteAction.Modify,
            },
          ]
        : []),
      {
        label: formatMessage({ id: 'CORE.BUTTON.REFUND-COMPLETELY', defaultMessage: 'Refund Completely' }),
        value: InvoiceCreditNoteAction.Refund,
      },
    ],
    [formatMessage, isOldInvoice],
  );

  const onActionChange = useCallback<EventWithDataHandler<ChangeEvent>>(() => {
    change(CreditNoteField.packageAction, null);
  }, [change]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field<BaseFieldProps<ChipsFieldProps>>
        name={CreditNoteField.action}
        component={ChipsField}
        onChange={onActionChange}
        props={{
          chips: actionOptions,
          isRequired: true,
          label: formatMessage({ id: 'CORE.LABEL.ACTION', defaultMessage: 'Action' }),
        }}
        validate={[required]}
      />
      {reasonOptions && (
        <Field<BaseFieldProps<ChipsFieldProps>>
          name={CreditNoteField.reason}
          component={ChipsField}
          props={{
            chips: reasonOptions,
            isRequired: true,
            label: formatMessage({ id: 'CORE.LABEL.REASON', defaultMessage: 'Reason' }),
          }}
          validate={[required]}
        />
      )}
      {isOtherReason && (
        <Row>
          <Col xs={12} lg={6}>
            <Fade>
              <Field
                name={CreditNoteField.otherReason}
                component={InputComponent}
                label={formatMessage({ id: 'CORE.LABEL.OTHER-REASON', defaultMessage: 'Other Reason' })}
                type="text"
                isRequired
                validate={[required]}
              />
            </Fade>
          </Col>
        </Row>
      )}
      {calculatePackageActionsLoading && canShowPackageActions && <Loader />}
      {!calculatePackageActionsLoading && canShowPackageActions && (
        <Field<BaseFieldProps<ChipsFieldProps>>
          name={CreditNoteField.packageAction}
          component={ChipsField}
          props={{
            chips: packageActionOptions,
            isRequired: true,
            label: formatMessage({ id: 'CORE.TEXT.WHAT-TO-DO-AFTER-RETURNING-PACKAGE-ITEMS' }),
          }}
          validate={[required]}
        />
      )}
    </Form>
  );
};

export const CreditNoteForm = reduxForm<CreditNoteFormData, CreditNoteFormProps, ErrorType>({})(FormComponent);
