import {
  AccountBalanceSummary,
  AppointmentStatus,
  BillingItemSourceType,
  BranchDeclarationOption,
  GovernmentIssuedIdType,
  InvoiceCreditNoteAction,
  InvoiceCreditNotePackageAction,
  InvoiceCreditNoteReason,
  InvoiceDiscountType,
  InvoiceInsuranceApprovalItem,
  InvoiceInsuranceReferralItem,
  InvoiceItem,
  InvoicePaymentMethod,
  InvoicePaymentMethodItem,
  InvoicePaymentMode,
  InvoiceStatus,
  InvoiceType,
  Option,
  PatientInsuranceDetailsOption,
  PatientStatus,
  PatientType,
} from 'app/types';

export interface InvoicingProcessPatient {
  clinicyId: string;
  dateOfBirth: string | null;
  fullNameArabic: string;
  fullNameEnglish: string;
  gender: Option | null;
  governmentIssuedId: string | null;
  governmentIssuedIdType: Option<GovernmentIssuedIdType> | null;
  id: string;
  internalFileNumber: string | null;
  mobileNumber: string | null;
  nationalId: string | null;
  nationality: Option | null;
  status: Option<PatientStatus> | null;
  patientType: Option<PatientType>;
  saudiCitizenship: boolean;
}

export interface InvoicingProcessSteps {
  items: InvoiceItem[] | null;
  paymentMethod: InvoicePaymentMethod | null;
  paymentMode: InvoicePaymentMode | null;
}

export interface ItemsSnapshot {
  creditNoteId: string;
  creditNoteNumber: string;
  items: InvoiceItem[];
  timeOfCreation: string;
}

// This is very bad idea to have a custom Appointment shape, but BE need to improve performance
export interface InvoicingProcessAppointment {
  createdAt: string;
  createdBy: string;
  end: string;
  id: string;
  internalFileNumber: string;
  note: string;
  number: string;
  product: Option;
  speciality: Option;
  start: string;
  status: Option<AppointmentStatus>;
  subSpeciality: Option;
}

export interface InvoicingProcess {
  activePatientInsurance: PatientInsuranceDetailsOption | null;
  appointment: InvoicingProcessAppointment | null;
  availablePackageQuantity: number;
  branchDeclaration: BranchDeclarationOption | null;
  clinic: Option;
  clinicBranch: Option;
  creditNoteCount: number;
  defaultItems: InvoiceItem[] | null; // added by frontend overwriteItems() function
  doctor: DoctorOption;
  draftInvoiceId: string | null;
  guardianPatient: InvoicingProcessPatient | null;
  hasMergePatientsBadge: boolean;
  hasPreviousAppointmentIn14Days: boolean;
  hasUsedPackageItems: boolean;
  id: string;
  invoiceId: string | null;
  invoiceNumber: string | null;
  invoicePackageSnapshotId: string | null;
  invoiceRequestId: string | null;
  invoiceStatus: Option<InvoiceStatus> | null;
  invoiceType: Option<InvoiceType> | null;
  isAnyInsuranceInvoiceNeedToBeRefunded: boolean | null;
  isDraft: boolean;
  itemsSnapshots: ItemsSnapshot[] | null;
  patient: InvoicingProcessPatient | null;
  patientBalance: AccountBalanceSummary;
  rootInvoiceId: string | null; // source invoice ID (first one)
  steps: InvoicingProcessSteps | null;
  visitNumber: string | null;
}

export interface InvoicingProcessPayload {
  data: InvoicingProcess;
}

export enum InvoicingProcessStep {
  Appointment = 'appointment',
  Details = 'details',
  Items = 'items',
  PaymentMethod = 'paymentMethod',
  PaymentMode = 'paymentMode',
  Summary = 'summary',
}

export enum InvoicingProcessMode {
  CreateCreditNote = 'createCreditNote',
  CreateInvoice = 'createInvoice',
  Draft = 'draft',
  Invoice = 'invoice',
  Invoices = 'invoices',
}

export interface CreditNoteConfiguration {
  action: InvoiceCreditNoteAction;
  otherReason: string | undefined;
  packageAction: InvoiceCreditNotePackageAction | undefined;
  reason: InvoiceCreditNoteReason;
}

export interface InvoicingProcessReducer {
  appointmentId: string | null;
  createCreditNoteLoading: boolean;
  createLoading: boolean;
  creditNoteConfiguration: CreditNoteConfiguration | null;
  data: InvoicingProcess | null;
  insuranceCompanyCacheUniq: string | null; // field is required to reset insuranceCompany field cache
  insuranceApprovalItems: {
    data: InvoiceInsuranceApprovalItem[];
    loading: boolean;
  };
  insuranceReferralItems: {
    data: InvoiceInsuranceReferralItem[];
    loading: boolean;
  };
  creditNotePackageActions: {
    data: CreditNotePackageAction[];
    loading: boolean;
  };
  items: InvoiceItem[];
  loading: boolean;
  mode: InvoicingProcessMode | null;
  paymentMethodItems: InvoicePaymentMethodItem[];
  invoicePackageSnapshotId: string | null;
  requestId: string | null;
  saveAsDraftLoading: boolean;
  selectedItem: InvoiceItem | null;
  selectedPaymentMethodItem: InvoicePaymentMethodItem | null;
  step: InvoicingProcessStep | null;
  updatePaymentsLoading: boolean;
}

export type InvoicingProcessInitialValues = Partial<InvoicingProcessReducer>;

// Invoicing Process Params
interface Insurance {
  approvalNumber: string;
  approvalThreshold?: number;
  approvalType: string;
  benefitLimit?: number;
  eligibilityNumber?: string;
  eligibilityType: string;
  end: string;
  insuranceApprovalId?: string;
  insuranceId: string;
  insuranceNumber: string;
  maxConsultFee?: number;
  maxPatientShare: number;
  nonReplaceableBrandMedicationsMaxPatientShare: number;
  nonReplaceableBrandMedicationsPatientSharePercentage: number;
  patientShare: number;
  primaryClinicMaxPatientShare: number;
  primaryClinicPatientSharePercentage: number;
  referralInsuranceApprovalId?: string;
  referralMaxPatientShare: number;
  referralPatientSharePercentage: number;
  replaceableBrandMedicationsMaxPatientShare: number;
  replaceableBrandMedicationsPatientSharePercentage: number;
  specializedClinicMaxPatientShare: number;
  specializedClinicPatientSharePercentage: number;
  start: string;
}

interface PaymentMode {
  insurance?: Insurance;
  governmentIssuedId: string;
  governmentIssuedIdType: string;
  payBy: string;
}

interface Item {
  billingItemId: string;
  billingItemSourceType: BillingItemSourceType;
  discountType: InvoiceDiscountType;
  discountValue: number;
  id: string;
  insuranceShare: number;
  patientShare: number;
  invoicePackageSnapshotId?: string;
  price: number;
  quantity: number;
  vat: number;
}

export interface Payment {
  amount: number;
  id: string;
  notes: string | null;
  otherPaymentMethod?: string;
  paymentMethod: string;
}

interface PaymentMethod {
  dueDate?: string;
  payments: Payment[];
}

interface Steps {
  items: Item[];
  paymentMethod: PaymentMethod;
  paymentMode: PaymentMode;
}

export interface InvoicingProcessBody extends Steps {
  branchDeclarationId: string | null;
  draftInvoiceId: string | null;
  guardianPatientId: string | null;
  invoicePackageSnapshotId: string | null;
  invoiceRequestId: string | null;
}

export interface CreditNoteBody {
  editedSteps: Steps;
  invoiceId: string;
  otherReason?: string;
  packageActionAfterCreditNote?: string;
  reason: string;
}

export interface ResetOptions {
  keepMode?: boolean;
  keepStep?: boolean;
}

interface DoctorOption extends Option {
  details: {
    certificateNumber?: string;
    isPrimaryCareSpeciality: boolean;
  };
}

export interface CreditNotePackageAction {
  action: Option<InvoiceCreditNotePackageAction>;
  blockingPerformerBillingItems: CreditNotePackageActionBillingItem[];
  isAllowed: boolean;
}

export interface CreditNotePackageActionBillingItem {
  id: string;
  internalCode: string | null;
  name: {
    ar: string;
    en: string;
  };
  minimumSellingPrice: number;
  sellingPrice: number;
}
