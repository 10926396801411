import { PatientGender } from 'app/types/Patient';
import { Option } from './Option';
import { RemoteFile } from './RemoteFile';
import { User } from './User';

export enum DoctorTitle {
  Prof = '0',
  Dr = '1',
  Mr = '2',
  Ms = '3',
  None = '9999',
}

export enum DoctorRank {
  Nurse = '1',
  Resident = '2',
  Specialist = '3',
  Consultant = '4',
  Professor = '5',
  None = '6',
  GP = '7',
  SeniorRegistrar = '8',
}

export enum DoctorStatus {
  Active = '0',
  Deleted = '1',
}

export interface Doctor extends User {
  aboutPractitionerInfo?: Option;
  acceptablePatientAgeFrom?: number;
  acceptablePatientAgeTo?: number;
  certificateNumber?: string;
  certificatesAr: string | null;
  certificatesEn: string | null;
  coordinatorPhoneNumber?: string;
  deletedAt: string | null;
  deletedByClinicyId: string | null;
  firstNameAr: string;
  firstNameEn: string;
  focusAr: string;
  focusEn: string;
  gender: Option<PatientGender>;
  hasPatientAgeRestriction?: boolean;
  id: string;
  image: RemoteFile;
  languages: Option[];
  lastNameAr: string;
  lastNameEn: string;
  license?: RemoteFile;
  licenseNumber: string;
  nationalIdentityCard?: RemoteFile;
  nationalIdentityCardNumber: string;
  nationality: Option;
  numberOfAppointments: number;
  numberOfBranches: number;
  phoneNumber: string;
  rank: Option<DoctorRank>;
  rating: number;
  speciality: Option | null;
  spokenLanguages: Option[];
  status: Option<DoctorStatus>;
  title: Option<DoctorTitle>;
}

export interface DoctorsDropdownOption extends Option {
  details: {
    speciality: Option;
    specialityType: Option;
  };
}
