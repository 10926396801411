import { Option } from './Option';

export interface BillingItem {
  branchId: string;
  clinicyId: string;
  cost: number;
  descriptionAr?: string;
  descriptionEn?: string;
  generalItemListNames: Option[];
  id: string;
  sourceId?: string;
  insuranceCompanies: BillingItemInsuranceCompany[];
  internalCode?: string;
  itemType: Option;
  linkToAllSpecialities: boolean;
  linkToAllSubSpecialities: boolean;
  hasCustomActivatedLinks: boolean;
  measureValue: number;
  medicalBilling: string;
  minimumSellingPrice: number;
  nameAr: string;
  nameEn: string;
  nameInInvoiceAr?: string;
  nameInInvoiceEn?: string;
  performerNames: Option[];
  price: number;
  saudisVatExempted: boolean;
  specialities: Option[];
  subSpecialities: Option[];
  unitMeasure: Option;
  isEnabled: boolean;
  isMainBillingItem: boolean;
}

export interface BillingItemMoreInfo extends BillingItem {
  matchingAssignedPractitioners: Option[];
  notMatchingAssignedPractitioners: Option[];
}

export interface BillingItemInsuranceCompany {
  companyId: string;
  companyNameAr: string;
  companyNameEn: string;
  price: number;
  activated?: boolean;
}

export interface BillingItemInsuranceCompanyOption extends Option {
  details: {
    price: number;
  };
}

export interface BillingItemInsuranceItem {
  insuranceCompanyId: string;
  price: number;
}

export enum BillingItemType {
  Consultation = '1',
  Service = '2',
  Procedure = '3',
  Lab = '4',
  Radiology = '5',
  MedicalProduct = '6',
  NonMedicalProduct = '7',
  Consumables = '8',
}

export enum BillingItemSourceType {
  BillingItem = '1',
  Medication = '2',
  Package = '3',
}

export enum BillingItemHierarchyType {
  Regular = '0',
  Medication = '1',
}

export enum BillingItemActionLogSource {
  Manual = '0',
  MassAdding = '1',
  Loomx = '2',
  System = '3',
}

export type ChangesData = {
  id: string;
  internalCode?: string;
  clinicyId: string;
  medicalBilling?: string;
  nameEn: string;
  nameAr: string;
  descriptionEn?: string;
  descriptionAr?: string;
  nameInInvoiceEn?: string;
  nameInInvoiceAr?: string;
  cost?: number;
  sellingPrice: number;
  minimumSellingPrice: number;
  saudisVatExempted: Option;
  enabled: Option;
  itemType: Option;
  unitMeasure: Option;
  measureValue: string;
  linkToAllSpecialities: Option;
  specialities: Option[];
  linkToAllSubSpecialities: Option;
  subSpecialities: Option[];
};

export type BranchBillingItemActionLog = {
  id: string;
  branchId: string;
  billingItem: Option;
  timeOfCreation: string;
  action: Option;
  element: Option;
  source: Option<BillingItemActionLogSource>;
  sourceReferenceId?: string;
  from?: ChangesData;
  to?: ChangesData;
  actionTaker?: Option;
};
