import { BillingItemSourceType, InvoiceBillingItemOption, InvoiceDiscountType, Option } from 'app/types';

export const ItemField = {
  discountType: 'discountType',
  discountValue: 'discountValue',
  item: 'item',
  itemSourceType: 'itemSourceType',
  quantity: 'quantity',
  totalPrice: 'totalPrice',
} as const;

export interface ItemFormData {
  [ItemField.discountType]: InvoiceDiscountType;
  [ItemField.discountValue]?: string;
  [ItemField.itemSourceType]: Option<BillingItemSourceType>;
  [ItemField.item]: InvoiceBillingItemOption;
  [ItemField.quantity]: number;
  [ItemField.totalPrice]: number;
}
