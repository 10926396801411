import { useCallback, useRef } from 'react';
import toast from 'react-hot-toast';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { Dispatch } from 'redux';
import { submit, touch } from 'redux-form';
import { DecoratedFormProps, FormSubmitHandler } from 'redux-form/lib/reduxForm';
import { useAppDispatch, useAppIntl, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_CREDIT_NOTE, FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import {
  fetchCreditNote,
  setCreditNoteConfiguration,
  setMode,
} from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { CreditNoteConfiguration, InvoicingProcessMode } from 'app/redux/invoicingProcess/types';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { ErrorType, InvoiceCreditNoteAction } from 'app/types';
import {
  CreditNoteForm,
  CreditNoteFormProps,
} from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteForm/creditNoteForm';
import {
  CreditNoteField,
  CreditNoteFormData,
} from 'app/features/invoicingProcess/modals/creditNoteModal/creditNoteForm/types';
import { useOnCalculatePackageActions } from 'app/features/invoicingProcess/process/actions/helpers/useOnCalculatePackageActions';
import { useOnCreateCreditNote } from 'app/features/invoicingProcess/process/actions/helpers/useOnCreateCreditNote';
import { ConflictedPackageAction } from 'app/features/invoicingProcess/process/components/conflictedPackageAction/conflictedPackageAction';
import { scrollToTop } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const CreditNoteModal = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const modalRef = useRef(null);
  const { createCreditNoteLoading, onCreateCreditNote } = useOnCreateCreditNote();
  const isOpen = useAppSelector((state) => state.modal.creditNote.isOpen);
  const invoiceId = useAppSelector((state) => state.invoicingProcess.data?.invoiceId);
  const hasUsedPackageItems = useAppSelector((state) => !!state.invoicingProcess.data?.hasUsedPackageItems);
  const packageAction: CreditNoteFormData['packageAction'] | undefined = useAppSelector(
    (state) => state.form[FORMS_CREDIT_NOTE]?.values?.[CreditNoteField.packageAction],
  );
  const { calculatePackageActionsLoading, conflictedPackageAction, onCalculatePackageActions } =
    useOnCalculatePackageActions(packageAction);

  const closeModal = useCallback(() => {
    dispatch(toggleModal('creditNote', false));
  }, [dispatch]);

  const submitForm = useCallback(() => {
    dispatch(submit(FORMS_CREDIT_NOTE));
  }, [dispatch]);

  const onChange = useCallback(
    (
      values: Partial<CreditNoteFormData>,
      dispatch: Dispatch,
      props: DecoratedFormProps<CreditNoteFormData, CreditNoteFormProps, ErrorType>,
      previousValues: Partial<CreditNoteFormData>,
    ) => {
      // #1. Check Packages Actions
      if (hasUsedPackageItems) {
        const hasActionChanged = previousValues.action !== values.action;
        const hasReasonChanged = previousValues.reason !== values.reason;

        if (
          values.action === InvoiceCreditNoteAction.Refund &&
          values.reason &&
          (hasActionChanged || hasReasonChanged)
        ) {
          onCalculatePackageActions({
            action: values.action,
            otherReason: '-', // BE issue - we should remove this validation for calculation case
            packageAction: undefined,
            reason: values.reason,
          });
        }
      }
    },
    [hasUsedPackageItems, onCalculatePackageActions],
  );

  const onSubmit = useCallback<FormSubmitHandler<CreditNoteFormData, CreditNoteFormProps, ErrorType>>(
    async (values, dispatch) => {
      const creditNoteConfiguration: CreditNoteConfiguration = {
        action: values.action,
        otherReason: values.otherReason,
        packageAction: values.packageAction,
        reason: values.reason,
      };

      // Set credit note configuration
      dispatch(setCreditNoteConfiguration(creditNoteConfiguration));

      // Trigger credit note flow
      if (values.action === InvoiceCreditNoteAction.Refund) {
        await onCreateCreditNote(creditNoteConfiguration);
      } else if (invoiceId) {
        dispatch(toggleModal('creditNote', false));
        await dispatch(fetchCreditNote(invoiceId));
        toast.success('CORE.TEXT.CREDIT-IS-READY', { duration: 5000 });
        dispatch(setMode(InvoicingProcessMode.CreateCreditNote));
        dispatch(touch(FORMS_INVOICING_PROCESS_PAYMENT_MODE, ...Object.keys(PaymentModeField)));
        scrollToTop();
      }
    },
    [invoiceId, onCreateCreditNote],
  );

  useModalCleaner('creditNote', modalRef);

  return (
    <Modal centered isOpen={isOpen} ref={modalRef} size="lg" toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        {formatMessage({ id: 'CORE.BUTTON.CREATE-CREDIT-NOTE', defaultMessage: 'Create Credit Note' })}
      </ModalHeader>
      <ModalBody className="pb-1">
        <CreditNoteForm form={FORMS_CREDIT_NOTE} onChange={onChange} onSubmit={onSubmit} />
        {!calculatePackageActionsLoading && conflictedPackageAction && (
          <ConflictedPackageAction packageAction={conflictedPackageAction} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-responsive btn-responsive--lg"
          color="primary-gradient"
          disabled={createCreditNoteLoading || calculatePackageActionsLoading || !!conflictedPackageAction}
          onClick={submitForm}
          style={{ minWidth: 160 }}
        >
          {createCreditNoteLoading ? (
            <Spinner size="sm" />
          ) : (
            formatMessage({ id: 'CORE.BUTTON.CONTINUE', defaultMessage: 'Continue' })
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
