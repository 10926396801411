import { Option } from 'app/types';

export enum InsuranceClaimsTabs {
  Claimed = 'claimed',
  Unclaimed = 'unclaimed',
  Settlement = 'settlement',
  Canceled = 'canceled',
}

export interface BaseInsuranceClaim {
  id: string;
  status: Option<InsuranceClaimsSubmitStatus>;
}

export interface InsuranceClaimPatient {
  id: string;
  clinicyMrn: string;
  phoneNumber: string;
  internalFileNumber: string;
  names: Option;
}

export interface MoveToSharedBody {
  insuranceClaimIds: string[];
  comment?: string;
  amount?: number;
}

export interface AddCommentBody {
  comment: string;
}

export interface ApproveSelectedClaimsBody {
  comment?: string;
  approvedItems: { id: string; quantity: string }[];
}

export interface ApproveSClaimsByAmountBody {
  amount: string;
  comment?: string;
}

export interface ClaimAttachment {
  id: string;
  statementId: string;
  name: string;
  timeOfCreation: string;
  createdBy: Option;
}

export interface ClaimComment {
  id: string;
  comment: string;
  timeOfCreation: string;
  createdBy: Option;
}

export interface InsuranceClaimDetails {
  id: string;
  baseInsuranceClaimForOtherClaims: boolean;
  baseInsuranceClaim: { id: string; status: Option };
  claimNumber: string;
  visitNumber: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceDate: string;
  appointmentId: string;
  insuranceApprovalId: string;
  approvalNumber: string;
  clinicId: string;
  branch: Option;
  insuranceCompany: Option;
  typeOfEncounter: Option;
  patient: {
    id: string;
    clinicyMrn: string;
    phoneNumber: string;
    internalFileNumber: string;
    names: Option;
  };
  grossAmount: number;
  discountAmount: number;
  insuranceTotalVatAmount: number;
  netPatientShare: number;
  grandInsuranceShare: number;
  statementId: string;
  insuranceContractor: Option;
  actionStatuses: Option[];
  invoiceItems: InsuranceClaimInvoiceItem[];
}

export interface InsuranceClaimInvoiceItem {
  id: string;
  billingItem: Option;
  quantity: number;
  unitNetPrice: number;
  vatValue: number;
  netPrice: number;
  grossPrice: number;
  discountAmount: number;
  totalToPay: number;
  patientShare: number;
  insuranceShare: number;
}

export interface UnclaimedSummary {
  notClaimedAmount: number;
  vatAmount: number;
  grandTotal: number;
}

export interface ClaimedSummary {
  netClaimedAmount: number;
  vatAmount: number;
  grandTotal: number;
}

export interface CancelledClaimsSummary {
  cancelledClaimAmount: number;
  vatAmount: number;
  grandTotal: number;
}

export interface SettledClaimsSummary {
  claimGrandTotalAmount: number;
  approvedClaimTotalAmount: number;
  rejectedClaimTotalAmount: number;
}

export interface UnclaimedInsurance {
  hasMergePatientsBadge: boolean;
  id: string;
  // [i] if true, baseInsuranceClaim will be null
  baseInsuranceClaimForOtherClaims: boolean;
  baseInsuranceClaim?: BaseInsuranceClaim;
  billingItemsBased: boolean;
  claimNumber?: string;
  visitNumber: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceDate: string;
  appointmentId: string;
  // [i] when insuranceApprovalId is filled - referralInsuranceApprovalId is null and vice versa!
  insuranceApprovalId?: string;
  referralInsuranceApprovalId?: string;
  approvalNumber: string;
  clinicId: string;
  branch: Option;
  insuranceCompany: Option;
  typeOfEncounter: Option<InsuranceClaimsTypeOfEncounter>;
  patient: InsuranceClaimPatient;
  payment?: InsuranceClaimPayment;
  grossAmount: number;
  discountAmount: number;
  insuranceTotalVatAmount: number;
  netPatientShare: number;
  grandInsuranceShare: number;
  statementId: string;
  insuranceContractor: Option;
  actionStatuses: Option<InsuranceClaimsStatus>[];
  settlementStatus?: Option<InsuranceClaimSettlementStatus>;
}

export type ClaimedInsurance = Omit<UnclaimedInsurance, 'actionStatuses' | 'insuranceContractor'>;

export interface InsuranceClaimPayment {
  paymentId: string;
  commentId: string | null;
  comment: string | null;
  amount: number;
  paidDate: string;
  paymentType: InsuranceClaimSettlementPaymentType;
  referenceRecordId: string | null;
  referenceTable: InsuranceClaimSettlementPaymentReferenceTable;
}

export interface ClaimedInsurancesStatement {
  id: string;
  contractor: Option;
  statementNumber: string;
  claimStartDate: string;
  claimEndDate: string;
  claimSubmissionDate: string;
  claimSubmissionDateActive: boolean;
  claimedBy: Option;
}

export enum InsuranceClaimsTypeOfEncounter {
  Outpatient = '0',
  Er = '1',
}

export enum InsuranceClaimsSubmitStatus {
  Unclaimed = '0',
  Claimed = '1',
  Cancelled = '2',
  Settled = '3',
}

export enum InsuranceClaimsStatus {
  LabSampleNotCollected = '0',
  LabReportMissing = '1',
  ScanNotTaken = '2',
  ScanReportMissing = '3',
  Completed = '4',
}

export enum InsuranceClaimSettlementStatus {
  NotDefined = '0',
  Approved = '1',
  PartiallyApproved = '2',
  ConsolidatedSettlement = '3',
  Rejected = '4',
}

export enum InsuranceClaimSettlementPaymentType {
  ManualFullApprove = 0,
  ManualBySelectedItems = 1,
  ManualByAmount = 2,
  ConsolidatedPaymentByAmount = 3,
  InsurancePaymentFullApprove = 4,
  InsurancePaymentPartialApprove = 5,
}

export enum InsuranceClaimSettlementPaymentReferenceTable {
  None = 0,
  StatementPayment = 1,
  SettlementFile = 2,
}

type CommonActionLog = {
  id: string;
  timeOfCreation: string;
  medicalInstitution: Option;
  branch: Option;
  element: Option;
  action: Option;
  actionTaker: Option;
};

export type InsuranceClaimsActionLog = {
  claimNumber: string;
  visitNumber: string;
  insuranceClaimId: string;
  from?: ClaimsFromToModel;
  to?: ClaimsFromToModel;
} & CommonActionLog;

export type InsuranceStatementActionLog = {
  insuranceClaimStatementId: string;
  statementNumber: string;
  from?: StatementFromToModel;
  to?: StatementFromToModel;
} & CommonActionLog;

export interface ClaimComment {
  comment: string;
}

export interface ClaimPayment {
  amount: number;
  paidDate: string;
  comment?: string;
}

export type StatementFromToModel = {
  id: string;
  contractor: Option;
  statementNumber: string;
  claimStartDate: string;
  claimEndDate: string;
  claimSubmissionDate: string;
  attachments?: Option[];
  payments?: ClaimPayment[];
};

export type ClaimsFromToModel = {
  claimNumber: string;
  invoiceNumber: string;
  visitNumber: string;
  statementNumber?: string;
  approvalNumber: string;
  insuranceContractor: Option;
  insuranceCompany: Option;
  totalGross: number;
  totalDiscount: number;
  totalInsuranceShareVat: number;
  totalNetPatientShare: number;
  totalInsuranceShare: number;
  typeOfEncounter: Option<InsuranceClaimsTypeOfEncounter>;
  claimSubmitStatus: Option<InsuranceClaimsSubmitStatus>;
  claimSettlementStatus: Option<InsuranceClaimSettlementStatus>;
  comments?: ClaimComment[];
  payments?: ClaimPayment[];
};

export interface InsuranceClaimSettlementFile {
  id: string;
  insuranceContractorId: string;
  name: string;
  timeOfCreation: string;
  uploadedBy: Option;
}
