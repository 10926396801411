import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  AssignItemToPractitionersBody,
  BillingItemsPerformersQueryParams,
  BranchBillingItemActionParams,
  BranchBillingItemMassAddingAttempt,
  BranchBillingItemMassAddingItemDetails,
  BranchBillingItemMassAddingQueryParams,
  BranchBillingItemsActionLogsParams,
  BranchBillingItemsQueryParams,
  BranchBillingItemsUserLogsParams,
  BranchBillingItemWarningsParams,
  CheckBillingItemInternalCodeParams,
  CreateMasterBranchBillingItemValues,
  DisableBranchBillingItemActionParams,
  EditBillingItemRequestData,
  ExportPractitionerBillingItemsParams,
  PerformerBillingItemActionParams,
  TogglePerformerBillingItemStatusParams,
  UpdatePractitionerBillingItemBody,
} from 'app/services/billingItems/types';
import { GetErrorFileResponse, TriggerMassAddingParams } from 'app/services/massAdding/types';
import {
  BillingItem,
  BillingItemMoreInfo,
  BranchBillingItemActionLog,
  MassAddingRow,
  Option,
  PackageWarning,
  PerformerBillingItem,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
  RemoteFile,
  UserLog,
} from 'app/types';

export const branchBillingItemsApi = createApi({
  reducerPath: 'branchBillingItemsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'BranchBillingItem',
    'BranchBillingItems',
    'PerformerBillingItem',
    'PerformerBillingItems',
    'Performers',
    'MassAddingHistory',
  ],
  endpoints: (builder) => ({
    // Branch Billing Items
    exportBranchBillingItems: builder.query<void, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/excel`,
        method: 'GET',
        params,
      }),
    }),
    getBranchBillingItems: builder.query<
      QueryResponseWithPagination<BillingItem[]>,
      { branchId: string; params: BranchBillingItemsQueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items`,
        method: 'GET',
        params,
      }),
      providesTags: ['BranchBillingItems'],
    }),
    getBranchBillingItem: builder.query<QueryResponse<BillingItem>, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'GET',
      }),
      providesTags: ['BranchBillingItem'],
    }),
    getBranchBillingItemMoreInfo: builder.query<QueryResponse<BillingItemMoreInfo>, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/more-info`,
        method: 'GET',
      }),
      providesTags: ['BranchBillingItem'],
    }),
    addBranchBillingItem: builder.mutation<void, { branchId: string; body: CreateMasterBranchBillingItemValues }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/branch-billing-items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BranchBillingItems'],
    }),
    editBranchBillingItem: builder.mutation<void, EditBillingItemRequestData>({
      query: ({ params: { branchId, branchBillingItemId }, body }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchBillingItems', 'BranchBillingItem'],
    }),
    //TODO [PT-9812] Waiting for integration with Branch Service BI
    editBranchServiceBillingItem: builder.mutation<void, EditBillingItemRequestData>({
      query: ({ params: { branchId, branchBillingItemId }, body }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'PUT',
        body,
      }),
    }),
    removeBranchBillingItem: builder.mutation<void, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BranchBillingItems', 'BranchBillingItem'],
    }),
    enableBranchBillingItem: builder.mutation<void, BranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/enable`,
        method: 'POST',
      }),
      invalidatesTags: ['BranchBillingItems', 'BranchBillingItem'],
    }),
    disableBranchBillingItem: builder.mutation<void, DisableBranchBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, removeFromPackages }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/disable`,
        method: 'POST',
        params: { removeFromPackages },
      }),
      invalidatesTags: ['BranchBillingItems', 'BranchBillingItem'],
    }),

    // Practitioner's Billing Items
    exportPerformersBillingItems: builder.query<
      void,
      { branchId: string; params: ExportPractitionerBillingItemsParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/performer-billing-items/excel`,
        method: 'GET',
        params,
      }),
    }),
    getPerformers: builder.query<
      QueryResponse<Option[]>,
      { branchId: string; params: BillingItemsPerformersQueryParams }
    >({
      query: ({ branchId, params }) => ({
        url: `/branch/${branchId}/billing-items/performers`,
        method: 'GET',
        params,
      }),
      providesTags: ['Performers'],
    }),
    getPerformerBillingItems: builder.query<
      QueryResponseWithPagination<PerformerBillingItem[]>,
      { branchId: string; performerId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, performerId, params }) => ({
        url: `branch/${branchId}/performers/${performerId}/performer-billing-items`,
        method: 'GET',
        params,
      }),
      providesTags: ['PerformerBillingItems'],
    }),
    getPerformerBillingItem: builder.query<QueryResponse<PerformerBillingItem>, PerformerBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'GET',
      }),
      providesTags: ['PerformerBillingItem'],
    }),
    assignItemToPerformers: builder.mutation<void, { branchId: string; body: AssignItemToPractitionersBody }>({
      query: ({ branchId, body }) => ({
        url: `branches/${branchId}/performer-billing-items`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PerformerBillingItem', 'PerformerBillingItems', 'Performers'],
    }),
    editPerformerBillingItem: builder.mutation<
      void,
      { params: PerformerBillingItemActionParams; body: UpdatePractitionerBillingItemBody }
    >({
      query: ({ params: { branchId, branchBillingItemId, performerBillingItemId }, body }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PerformerBillingItem', 'PerformerBillingItems'],
    }),
    removePerformerBillingItem: builder.mutation<void, PerformerBillingItemActionParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PerformerBillingItem', 'PerformerBillingItems'],
    }),
    togglePerformerBillingItemStatus: builder.mutation<void, TogglePerformerBillingItemStatusParams>({
      query: ({ branchId, branchBillingItemId, performerBillingItemId, enabled }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/performer-billing-items/${performerBillingItemId}/enabled`,
        method: 'PUT',
        params: { enabled },
      }),
      invalidatesTags: ['PerformerBillingItem', 'PerformerBillingItems'],
    }),

    // Other
    getBranchBillingItemsWarnings: builder.query<
      QueryResponseWithPagination<PackageWarning[]>,
      BranchBillingItemWarningsParams
    >({
      query: ({ branchId, branchBillingItemId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/${branchBillingItemId}/warnings`,
        method: 'GET',
        params,
      }),
    }),
    checkInternalCode: builder.query<QueryResponse<{ exists: boolean }>, CheckBillingItemInternalCodeParams>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/check-internal-code`,
        method: 'GET',
        params,
      }),
    }),

    // Mass Adding
    getMassAddingAttempts: builder.query<
      QueryResponseWithPagination<BranchBillingItemMassAddingAttempt[]>,
      { branchId: string; params?: BranchBillingItemMassAddingQueryParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts`,
        method: 'GET',
        params,
      }),
      providesTags: ['MassAddingHistory'],
    }),
    getMassAddingReport: builder.query<void, { branchId: string; params?: BranchBillingItemMassAddingQueryParams }>({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts/history-excel`,
        method: 'GET',
        params,
      }),
    }),
    getMassAddingAttempt: builder.query<
      QueryResponse<BranchBillingItemMassAddingItemDetails>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}`,
        method: 'GET',
      }),
    }),
    getMassAddingAttemptItems: builder.query<
      QueryResponseWithPagination<MassAddingRow[]>,
      { branchId: string; massAddingAttemptId: string; params?: QueryParams }
    >({
      query: ({ branchId, massAddingAttemptId, params }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/rows`,
        method: 'GET',
        params,
      }),
    }),
    getMassAddingErrorsFile: builder.query<GetErrorFileResponse, { branchId: string; massAddingAttemptId: string }>({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/errors-excel`,
        method: 'GET',
      }),
    }),
    getMassAddingUploadedFile: builder.query<
      QueryResponse<RemoteFile>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/uploaded-excel`,
        method: 'GET',
      }),
    }),
    getMassAddingBeforeUpdatesFile: builder.query<
      QueryResponse<RemoteFile>,
      { branchId: string; massAddingAttemptId: string }
    >({
      query: ({ branchId, massAddingAttemptId }) => ({
        url: `branches/${branchId}/mass-adding-attempts/${massAddingAttemptId}/before-updates-excel`,
        method: 'GET',
      }),
    }),
    triggerMassAdding: builder.mutation<void, TriggerMassAddingParams>({
      query: ({ body, branchId }) => ({
        body,
        method: 'POST',
        url: `branches/${branchId}/mass-adding-attempts`,
      }),
      invalidatesTags: ['MassAddingHistory'],
    }),

    // Branch Billing Items Logs
    getBranchBillingItemsActionLogs: builder.query<
      QueryResponseWithPagination<BranchBillingItemActionLog[]>,
      { branchId: string; params?: BranchBillingItemsActionLogsParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/action-logs`,
        method: 'GET',
        params,
      }),
    }),
    getBranchBillingItemsUserLogs: builder.query<
      QueryResponseWithPagination<UserLog[]>,
      { branchId: string; params?: BranchBillingItemsUserLogsParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-items/user-logs`,
        method: 'GET',
        params,
      }),
    }),
    exportBillingItemsActionLog: builder.query<void, { branchId: string; params?: BranchBillingItemsActionLogsParams }>(
      {
        query: ({ branchId, params }) => ({
          url: `branches/${branchId}/branch-billing-items/action-logs/excel`,
          method: 'GET',
          params,
        }),
      },
    ),
  }),
});

export const {
  useAddBranchBillingItemMutation,
  useAssignItemToPerformersMutation,
  useDisableBranchBillingItemMutation,
  useEditBranchBillingItemMutation,
  useEditBranchServiceBillingItemMutation,
  useEditPerformerBillingItemMutation,
  useEnableBranchBillingItemMutation,
  useGetBranchBillingItemsQuery,
  useGetBranchBillingItemsWarningsQuery,
  useGetMassAddingAttemptItemsQuery,
  useGetMassAddingAttemptQuery,
  useGetMassAddingAttemptsQuery,
  useGetPerformersQuery,
  useGetPerformerBillingItemsQuery,
  useLazyExportBranchBillingItemsQuery,
  useLazyExportPerformersBillingItemsQuery,
  useLazyGetBranchBillingItemMoreInfoQuery,
  useLazyGetBranchBillingItemQuery,
  useLazyGetBranchBillingItemsWarningsQuery,
  useLazyGetMassAddingBeforeUpdatesFileQuery,
  useLazyGetMassAddingErrorsFileQuery,
  useLazyGetMassAddingReportQuery,
  useLazyGetMassAddingUploadedFileQuery,
  useRemoveBranchBillingItemMutation,
  useRemovePerformerBillingItemMutation,
  useTogglePerformerBillingItemStatusMutation,
  useTriggerMassAddingMutation,
  useCheckInternalCodeQuery,
  useGetBranchBillingItemsActionLogsQuery,
  useGetBranchBillingItemsUserLogsQuery,
  useLazyExportBillingItemsActionLogQuery,
} = branchBillingItemsApi;
