import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  BranchPackagesModalBody,
  ExportPractitionerPackagesParams,
  PractitionerPackagesBody,
} from 'app/services/billingItemsPackages/types';
import { Option, Package, QueryParams, QueryResponse, QueryResponseWithPagination } from 'app/types';

export const billingItemsPackagesApi = createApi({
  reducerPath: 'billingItemsPackagesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['BranchPackages', 'PerformerPackages', 'Performers'],
  endpoints: (builder) => ({
    // Branch Billing Items Packages
    getBillingItemsPackages: builder.query<
      QueryResponseWithPagination<Package[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/branch-billing-packages`,
        params,
      }),
      providesTags: ['BranchPackages'],
    }),
    // unused atm, data for MoreInfo and Edit is taken from the list
    getBillingItemsPackage: builder.query<QueryResponse<Package>, { branchId: string; packageId: string }>({
      query: ({ branchId, packageId }) => ({
        url: `/branches/${branchId}/branch-billing-packages/${packageId}`,
        method: 'GET',
      }),
    }),
    addBillingItemPackage: builder.mutation<void, { branchId: string; body: BranchPackagesModalBody }>({
      query: ({ branchId, body }) => ({
        url: `/branches/${branchId}/branch-billing-packages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BranchPackages', 'Performers'],
    }),
    editBillingItemPackage: builder.mutation<
      void,
      { branchId: string; packageId: string; body: BranchPackagesModalBody }
    >({
      query: ({ branchId, packageId, body }) => ({
        url: `branches/${branchId}/branch-billing-packages/${packageId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchPackages', 'Performers'],
    }),
    removeBillingItemPackage: builder.mutation<void, { branchId: string; packageId: string }>({
      query: ({ branchId, packageId }) => ({
        url: `branches/${branchId}/branch-billing-packages/${packageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BranchPackages', 'Performers'],
    }),
    exportBillingItemsPackages: builder.query<void, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `/branches/${branchId}/branch-billing-packages/excel`,
        method: 'GET',
        params,
      }),
    }),

    // Performer Billing Items Packages
    getPerformers: builder.query<QueryResponse<Option[]>, { branchId: string; params: QueryParams | undefined }>({
      query: ({ branchId, params }) => ({
        url: `branch/${branchId}/packages/performers`,
        method: 'GET',
        params,
      }),
      providesTags: ['Performers'],
    }),
    getPerformerBillingItemsPackages: builder.query<
      QueryResponseWithPagination<Package[]>,
      { branchId: string; performerId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, performerId, params }) => ({
        url: `/branch/${branchId}/performers/${performerId}/performer-billing-packages`,
        method: 'GET',
        params,
      }),
      providesTags: ['PerformerPackages'],
    }),
    // unused atm, data for MoreInfo is taken from the list
    getPerformerBillingItemsPackage: builder.query<QueryResponse<Package>, { branchId: string; packageId: string }>({
      query: ({ branchId, packageId }) => ({
        url: `branches/${branchId}/branch-billing-packages/${packageId}`,
        method: 'GET',
      }),
    }),
    assignBillingItemPackageToPerformers: builder.mutation<
      void,
      { branchId: string; packageId: string; body: PractitionerPackagesBody }
    >({
      query: ({ branchId, packageId, body }) => ({
        url: `branches/${branchId}/branch-billing-packages/${packageId}/performer-billing-packages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PerformerPackages', 'Performers'],
    }),
    editPerformerBillingItemPackage: builder.mutation<
      void,
      { branchId: string; packageId: string; performerPackageId: string; body: PractitionerPackagesBody }
    >({
      query: ({ branchId, packageId, performerPackageId, body }) => ({
        url: `/branches/${branchId}/branch-billing-packages/${packageId}/performer-billing-packages/${performerPackageId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PerformerPackages', 'Performers'],
    }),
    removePerformerBillingItemPackage: builder.mutation<
      void,
      { branchId: string; packageId: string; performerPackageId: string }
    >({
      query: ({ branchId, packageId, performerPackageId }) => ({
        url: `branches/${branchId}/branch-billing-packages/${packageId}/performer-billing-packages/${performerPackageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PerformerPackages', 'Performers'],
    }),
    togglePerformerBillingItemPackage: builder.mutation<
      void,
      { branchId: string; packageId: string; performerPackageId: string; enabled: boolean }
    >({
      query: ({ branchId, packageId, performerPackageId, enabled }) => ({
        url: `/branches/${branchId}/branch-billing-packages/${packageId}/performer-billing-packages/${performerPackageId}/enabled`,
        method: 'PUT',
        params: { enabled },
      }),
      invalidatesTags: ['PerformerPackages'],
    }),
    exportPerformerBillingItemsPackages: builder.query<
      void,
      { branchId: string; params: ExportPractitionerPackagesParams }
    >({
      query: ({ branchId, params }) => ({
        url: `branches/${branchId}/performer-billing-packages/excel`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetBillingItemsPackagesQuery,
  useGetPerformersQuery,
  useGetPerformerBillingItemsPackagesQuery,
  useLazyGetBillingItemsPackageQuery,
  useLazyExportBillingItemsPackagesQuery,
  useLazyExportPerformerBillingItemsPackagesQuery,
  useAddBillingItemPackageMutation,
  useAssignBillingItemPackageToPerformersMutation,
  useEditBillingItemPackageMutation,
  useRemoveBillingItemPackageMutation,
  useRemovePerformerBillingItemPackageMutation,
  useTogglePerformerBillingItemPackageMutation,
} = billingItemsPackagesApi;
