import { useMemo } from 'react';
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { getFormValues } from 'redux-form';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { FORMS_INVOICING_PROCESS_DETAILS, FORMS_INVOICING_PROCESS_PAYMENT_MODE } from 'app/shared';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { InvoicingProcess, InvoicingProcessMode, InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { DetailsFormData } from 'app/features/invoicingProcess/process/details/form/types';
import { validate as validateDetailsForm } from 'app/features/invoicingProcess/process/details/form/validate';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { checkIsPaymentModeItemsInvalid } from 'app/features/invoicingProcess/process/helpers/checkIsPaymentModeItemsInvalid';
import { validate as validatePaymentModeForm } from 'app/features/invoicingProcess/process/paymentMode/form/helpers/validate';
import { PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';
import styles from 'app/features/invoicingProcess/process/steps/steps.module.scss';

interface StepItem {
  isCompleted: boolean;
  isDisabled: boolean;
  isInvalid: boolean;
  isSelected: boolean;
  isVisible: boolean;
  key: InvoicingProcessStep;
  title: string;
}

export const Steps = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useAppIntl();
  const { isCreated, mode, step } = useInvoicingProcessState();
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId);
  const data: InvoicingProcess | null = useAppSelector((state) => state.invoicingProcess.data);
  const detailsFormValues: Partial<DetailsFormData> = useAppSelector((state) =>
    getFormValues(FORMS_INVOICING_PROCESS_DETAILS)(state),
  );
  const paymentModeFormValues: Partial<PaymentModeFormData> = useAppSelector((state) =>
    getFormValues(FORMS_INVOICING_PROCESS_PAYMENT_MODE)(state),
  );
  const items = useAppSelector((state) => state.invoicingProcess.items);
  const invoicePayBy = paymentModeFormValues?.['payBy']?.value;
  const invoiceEligibility = paymentModeFormValues?.['eligibility']?.value;
  const isPaymentModeItemsInvalid = checkIsPaymentModeItemsInvalid({ invoiceEligibility, invoicePayBy, items });

  // Invalid state
  const isDetailsInvalid = !appointmentId && !isEmpty(validateDetailsForm(detailsFormValues));
  const isPaymentModeInvalid =
    !isEmpty(validatePaymentModeForm(paymentModeFormValues, { appointmentId, invoicingProcess: data })) ||
    isPaymentModeItemsInvalid;
  const isItemsInvalid = !items?.length;

  const stepItems = useMemo<StepItem[]>(
    () =>
      [
        {
          isCompleted: true,
          isDisabled: false,
          isInvalid: isDetailsInvalid,
          isSelected: step === InvoicingProcessStep.Details,
          isVisible: !appointmentId,
          key: InvoicingProcessStep.Details,
          title: formatMessage({ id: 'CORE.TEXT.DETAILS', defaultMessage: 'Details' }),
        },
        {
          isCompleted: true,
          isDisabled: false,
          isInvalid: false,
          isSelected: step === InvoicingProcessStep.Appointment,
          isVisible: !!appointmentId,
          key: InvoicingProcessStep.Appointment,
          title: formatMessage({ id: 'CORE.LABEL.APPOINTMENT', defaultMessage: 'Appointment' }),
        },
        {
          isCompleted: !isPaymentModeInvalid,
          isDisabled: isDetailsInvalid || !data,
          isInvalid: isPaymentModeInvalid,
          isSelected: step === InvoicingProcessStep.PaymentMode,
          isVisible: true,
          key: InvoicingProcessStep.PaymentMode,
          title: formatMessage({ id: 'CORE.LABEL.PAYMENT-MODE', defaultMessage: 'Payment Mode' }),
        },
        {
          isCompleted: !isItemsInvalid,
          isDisabled: isDetailsInvalid || isPaymentModeInvalid,
          isInvalid: isItemsInvalid,
          isSelected: step === InvoicingProcessStep.Items,
          isVisible: true,
          key: InvoicingProcessStep.Items,
          title: formatMessage({ id: 'CORE.LABEL.INVOICE-ITEMS', defaultMessage: 'Invoice Items' }),
        },
        {
          isCompleted: true,
          isDisabled: isDetailsInvalid || isPaymentModeInvalid || isItemsInvalid,
          isInvalid: false,
          isSelected: step === InvoicingProcessStep.PaymentMethod,
          isVisible: true,
          key: InvoicingProcessStep.PaymentMethod,
          title: formatMessage({ id: 'CORE.LABEL.PAYMENTS', defaultMessage: 'Payments' }),
        },
        {
          isCompleted: true,
          isInvalid: false,
          isDisabled: isPaymentModeInvalid || isItemsInvalid,
          isSelected: step === InvoicingProcessStep.Summary,
          isVisible: isCreated,
          key: InvoicingProcessStep.Summary,
          title: formatMessage({ id: 'CORE.LABEL.SUMMARY', defaultMessage: 'Summary' }),
        },
      ].filter((item) => item.isVisible),
    [appointmentId, data, formatMessage, isCreated, isDetailsInvalid, isItemsInvalid, isPaymentModeInvalid, step],
  );

  const title = useMemo(() => {
    if (mode === InvoicingProcessMode.CreateCreditNote) {
      return formatMessage({ id: 'CORE.TEXT.CREDIT-NOTE', defaultMessage: 'Credit Note' });
    }

    return formatMessage({ id: 'CORE.TEXT.INVOICING-PROCESS', defaultMessage: 'Invoicing Process' });
  }, [formatMessage, mode]);

  return (
    <div className={styles.steps}>
      <h3 className={styles.title}>{title}</h3>
      {stepItems.map((stepItem) => (
        <div
          className={classNames(styles.step, 'slideInUp', {
            [styles.selected]: stepItem.isSelected,
            [styles.completed]: stepItem.isCompleted,
            [styles.invalid]: stepItem.isInvalid && !stepItem.isDisabled,
            [styles.disabled]: stepItem.isDisabled,
          })}
          key={stepItem.key}
          onClick={() => !stepItem.isDisabled && dispatch(setStep(stepItem.key))}
        >
          {stepItem.isInvalid && !stepItem.isDisabled ? (
            <IconAlertCircle className={styles.stepIcon} size={30} strokeWidth={1.8} />
          ) : (
            <IconCircleCheck className={styles.stepIcon} size={30} strokeWidth={1.8} />
          )}
          <h5 className={styles.stepTitle}>{stepItem.title}</h5>
        </div>
      ))}
    </div>
  );
};
