import { DraftInvoiceWithoutDetails, InvoiceRequest, InvoiceWithoutDetails } from 'app/redux/invoice/types';
import { generateInitialState, generateParams } from 'app/redux/remoteData/helpers';
import * as types from 'app/redux/remoteData/remoteData.types';
import {
  DashboardParams,
  DayCalendarsParams,
  PatientsRemoteParams,
  RemoteDataAction,
  UserLogParams,
  WeekCalendarParams,
} from 'app/redux/remoteData/types';
import {
  Allergy,
  Appointment,
  AppointmentAction,
  AppointmentInvoice,
  AttachmentResponseItem,
  AvailablePackage,
  Branch,
  DayCalendar,
  Doctor,
  GeneralNotification,
  InsuranceApprovalAction,
  InsuranceContract,
  InsurancePolicies,
  InvoiceLogItem,
  NotificationLog,
  PackageItem,
  Patient,
  PerformersAppointments,
  ReferralAction,
  RemoteData,
  RemoteDataParams,
  RemovedInsurancePolicies,
  UserLog,
  UserPerformance,
  VisitPractitionerAssessment,
} from 'app/types';
import { InsuranceBillingItemsContract } from 'app/types/InsuranceBillingItemsContract';
import { InsuranceRemovedContract } from 'app/types/InsuranceRemovedContract';
import { SpecialityOption } from 'app/types/SpecialityOption';
import { MedicationDetails } from 'app/features/insuranceContracts/shared/medicationToInsuranceForm/types';

export interface RemoteDataState {
  allergy: RemoteData<Allergy[]>;
  appointmentActions: RemoteData<AppointmentAction[]>;
  appointmentAttachments: RemoteData<AttachmentResponseItem[]>;
  appointmentInvoices: RemoteData<AppointmentInvoice[]>;
  archivedLog: RemoteData<NotificationLog[]>;
  availablePackages: RemoteData<AvailablePackage[]>;
  bookedAppointments: RemoteData<Appointment[]>;
  bookedWaitingAppointments: RemoteData<Appointment[]>;
  branchServices: RemoteData;
  branches: RemoteData<Branch[]>;
  conflictUpcomingAppointments: RemoteData;
  conflictWaitingUpcomingAppointments: RemoteData;
  contracts: RemoteData<InsuranceContract[]>;
  currentAppointments: RemoteData<Appointment[]>;
  currentLog: RemoteData<NotificationLog[]>;
  dayCalendars: RemoteData<DayCalendar[], RemoteDataParams & Partial<DayCalendarsParams>>;
  doctors: RemoteData<Doctor[]>;
  generalNotifications: RemoteData<GeneralNotification[]> & { unread: number };
  importPackageToInsuranceItems: RemoteData<PackageItem[]>;
  insuranceActions: RemoteData<InsuranceApprovalAction[]>;
  insuranceBillingItemsContractors: RemoteData<InsuranceBillingItemsContract[]>;
  insuranceBillingItemsInclusions: RemoteData<InsuranceBillingItemsContract[]>;
  insurancePoliciesSetUp: RemoteData<InsurancePolicies[]>;
  insuranceRemovedContractors: RemoteData<InsuranceRemovedContract[]>;
  insuranceRemovedPoliciesSetUp: RemoteData<RemovedInsurancePolicies[]>;
  invoiceLogs: RemoteData<InvoiceLogItem[]>;
  invoices: RemoteData<InvoiceWithoutDetails[]>;
  invoicesDraft: RemoteData<DraftInvoiceWithoutDetails[]>;
  invoicesRequest: RemoteData<InvoiceRequest[]>;
  passedAppointments: RemoteData<Appointment[]>;
  patients: RemoteData<Patient[], PatientsRemoteParams>;
  performersAppointments: RemoteData<PerformersAppointments[], RemoteDataParams & Partial<DashboardParams>>;
  referralLogs: RemoteData<ReferralAction[]>;
  replaceableMedications: RemoteData<MedicationDetails[]>;
  upcomingAppointments: RemoteData<Appointment[]>;
  userLogs: RemoteData<UserLog[], RemoteDataParams & Partial<UserLogParams>>;
  userScopedSpecialities: RemoteData<SpecialityOption[]>;
  usersPerformance: RemoteData<UserPerformance[], RemoteDataParams & Partial<DashboardParams>>;
  visitPractitionerAssessment: RemoteData<VisitPractitionerAssessment[]>;
  waitingPassedAppointments: RemoteData<Appointment[]>;
  waitingUpcomingAppointments: RemoteData<Appointment[]>;
  weekCalendar: RemoteData<DayCalendar[], RemoteDataParams & Partial<WeekCalendarParams>>;
}

const initialState: RemoteDataState = {
  allergy: generateInitialState(),
  appointmentActions: generateInitialState(),
  appointmentAttachments: generateInitialState(),
  appointmentInvoices: generateInitialState(),
  archivedLog: generateInitialState(),
  availablePackages: generateInitialState(),
  bookedAppointments: generateInitialState(),
  bookedWaitingAppointments: generateInitialState(),
  branchServices: generateInitialState(),
  branches: generateInitialState(),
  conflictUpcomingAppointments: generateInitialState(),
  conflictWaitingUpcomingAppointments: generateInitialState(),
  contracts: generateInitialState(),
  currentAppointments: generateInitialState(),
  currentLog: generateInitialState(),
  dayCalendars: generateInitialState(),
  doctors: generateInitialState(),
  generalNotifications: { ...generateInitialState(), unread: 0 },
  importPackageToInsuranceItems: generateInitialState(),
  insuranceActions: generateInitialState(),
  insuranceBillingItemsContractors: generateInitialState(),
  insuranceBillingItemsInclusions: generateInitialState(),
  insurancePoliciesSetUp: generateInitialState(),
  insuranceRemovedContractors: generateInitialState(),
  insuranceRemovedPoliciesSetUp: generateInitialState(),
  invoiceLogs: generateInitialState(),
  invoices: generateInitialState(),
  invoicesDraft: generateInitialState(),
  invoicesRequest: generateInitialState(),
  passedAppointments: generateInitialState(),
  patients: generateInitialState(),
  performersAppointments: generateInitialState(),
  referralLogs: generateInitialState(),
  replaceableMedications: generateInitialState(),
  upcomingAppointments: generateInitialState(),
  userLogs: generateInitialState(),
  userScopedSpecialities: generateInitialState(),
  usersPerformance: generateInitialState(),
  visitPractitionerAssessment: generateInitialState(),
  waitingPassedAppointments: generateInitialState(),
  waitingUpcomingAppointments: generateInitialState(),
  weekCalendar: generateInitialState(),
};

export const remoteDataReducer = (state = initialState, action: RemoteDataAction): RemoteDataState => {
  const name = action.meta?.name;
  const isSilent = action.meta?.isSilent;
  const isSoft = action.meta?.isSoft;

  switch (action.type) {
    case types.FETCH_DATA_REQUEST:
      return {
        ...state,
        ...(!isSilent && {
          [name]: {
            ...state[name],
            ...(!isSoft && {
              data: initialState[name].data,
            }),
            loading: true,
          },
        }),
      };
    case types.FETCH_DATA_SUCCESS:
      return {
        ...state,
        [name]: {
          ...state[name],
          ...action.payload,
          error: false,
          params: generateParams(action),
          loading: false,
        },
      };
    case types.FETCH_DATA_FAILURE:
      return {
        ...state,
        [name]: {
          ...state[name],
          error: true,
          loading: false,
        },
      };
    case types.RESET_DATA:
      return {
        ...state,
        [name]: initialState[name],
      };
    default:
      return state;
  }
};
