import { useCallback } from 'react';
import { OptionContext } from 'react-select';
import { getTranslation, useAppIntl } from 'app/helpers';
import { BranchStatusBadge } from 'app/components';
import { BranchStatus, Option } from 'app/types';

interface BranchDropdownOption extends Option {
  details?: {
    status: Option<BranchStatus>;
  };
}

export const useBranchOptionFormatter = (showClinicyId: boolean = false) => {
  const { locale } = useAppIntl();

  return useCallback(
    (option: BranchDropdownOption, meta: { context: OptionContext }) => {
      // Return - option selected
      if (meta.context === 'value') {
        return (
          <div className="d-flex align-items-center justify-content-between gap-2">
            <span>{getTranslation(option, locale)}</span>
            {showClinicyId && option?.clinicyId && (
              <small className="text-nowrap font-weight-light">{option.clinicyId}</small>
            )}
          </div>
        );
      }

      // Return - option in menu
      return (
        <div className="d-flex align-items-center justify-content-between gap-2">
          <span>{getTranslation(option, locale)}</span>
          <div className="d-flex align-items-center gap-2">
            {showClinicyId && option?.clinicyId && (
              <small className="text-nowrap font-weight-light">{option.clinicyId}</small>
            )}
            {option?.details?.status && (
              <BranchStatusBadge status={option.details.status} style={{ fontSize: '0.7rem' }} />
            )}
          </div>
        </div>
      );
    },
    [locale, showClinicyId],
  );
};
