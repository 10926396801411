import { FormErrors } from 'redux-form';
import { governmentIssuedId, price, required } from 'app/shared';
import { InvoicingProcess } from 'app/redux/invoicingProcess/types';
import { ErrorType, InvoiceEligibility, InvoicePayBy } from 'app/types';
import { checkIsInsuranceApprovalNullByDefault } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeField, PaymentModeFormData } from 'app/features/invoicingProcess/process/paymentMode/form/types';

export const validate = (
  values: Partial<PaymentModeFormData>,
  props: {
    appointmentId: string | null;
    invoicingProcess: InvoicingProcess | null;
  },
): FormErrors<PaymentModeFormData, ErrorType> => {
  let errors: Partial<{ [Property in keyof PaymentModeFormData]: ErrorType }> = {};

  const isPayByInsurance = values?.payBy?.value === InvoicePayBy.Insurance;
  const isInsuranceApprovalNullByDefault = checkIsInsuranceApprovalNullByDefault(
    props.invoicingProcess?.steps?.paymentMode?.insurance,
  );

  // #1. Check required fields
  let requiredFields: Array<keyof PaymentModeFormData> = isPayByInsurance
    ? [
        PaymentModeField.approvalType,
        PaymentModeField.end,
        PaymentModeField.governmentIssuedId,
        PaymentModeField.governmentIssuedIdType,
        PaymentModeField.insuranceClass,
        PaymentModeField.insuranceCompany,
        PaymentModeField.insuranceNumber,
        PaymentModeField.payBy,
        PaymentModeField.policyNumber,
        PaymentModeField.start,
      ]
    : [PaymentModeField.governmentIssuedId, PaymentModeField.governmentIssuedIdType, PaymentModeField.payBy];

  // #1.1 Conditionally required fields
  if (isPayByInsurance) {
    if (props.appointmentId) {
      requiredFields.push(PaymentModeField.eligibility);
    }

    if (values?.eligibility?.value === InvoiceEligibility.Eligible) {
      requiredFields.push(PaymentModeField.eligibilityNumber);
    }

    if (values?.eligibility?.value === InvoiceEligibility.ReferralIn) {
      requiredFields.push(PaymentModeField.insuranceReferralApproval);
    }

    if (values?.eligibility?.value === InvoiceEligibility.PreviousApproval && !isInsuranceApprovalNullByDefault) {
      requiredFields.push(PaymentModeField.insuranceApproval);
    }
  }

  requiredFields.forEach((field) => {
    const validation = required(values?.[field]);

    if (validation) {
      errors = {
        ...errors,
        [field]: validation,
      };
    }
  });

  if (values) {
    // #2. Check national ID (if patient is Saudi Arabian citizen)
    if (values.governmentIssuedId && values.governmentIssuedIdType) {
      const governmentIssuedIdValidation = governmentIssuedId(values.governmentIssuedIdType.value)(
        values.governmentIssuedId,
      );

      if (governmentIssuedIdValidation) {
        errors = {
          ...errors,
          governmentIssuedId: governmentIssuedIdValidation,
        };
      }
    }

    if (isPayByInsurance) {
      // #3. Check dates
      if (values.start && values.end && values.end < values.start) {
        errors = {
          ...errors,
          end: 'ERRORS.END-DATE-MUST-BE-GREATER',
        };
      }

      // #4. Check percent fields
      const patientShare = values?.patientShare ? Number(values.patientShare) : 0;

      if (patientShare < 0 || patientShare > 100) {
        errors = {
          ...errors,
          patientShare: 'ERRORS.VALUE',
        };
      }

      // #5. Check price fields
      let priceFields = [
        PaymentModeField.approvalThreshold,
        PaymentModeField.benefitLimit,
        PaymentModeField.maxConsultFee,
        PaymentModeField.maxPatientShare,
        PaymentModeField.nonReplaceableBrandMedicationsMaxPatientShare,
        PaymentModeField.primaryClinicMaxPatientShare,
        PaymentModeField.referralToSpecializedClinicMaxPatientShare,
        PaymentModeField.replaceableBrandMedicationsMaxPatientShare,
        PaymentModeField.specializedClinicMaxPatientShare,
      ];

      priceFields.forEach((field) => {
        const validation = price(values[field]);

        if (validation) {
          errors = {
            ...errors,
            [field]: validation,
          };
        }
      });
    }
  }

  return errors;
};
