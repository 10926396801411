import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchBillingPackagesState, ModalMode } from 'app/redux/branchBillingPackages/types';
import { Option, Package, PackageItem } from 'app/types';

const initialState: BranchBillingPackagesState = {
  expandedPerformer: null,
  mode: ModalMode.Unknown,
  packageItems: [],
  selectedPackage: null,
};

const branchBillingPackages = createSlice({
  name: 'branchBillingPackages',
  initialState,
  reducers: {
    addPackageItem: (state, action: PayloadAction<PackageItem>) => {
      if (!state.packageItems.some((item) => item.id === action.payload.id)) {
        state.packageItems = [...state.packageItems, action.payload];
      }
    },
    removePackageItem: (state, action: PayloadAction<string>) => {
      state.packageItems = state.packageItems.filter((item) => item.id !== action.payload);
    },
    setSelectedPackage: (state, action: PayloadAction<{ mode: ModalMode; data: Package | null }>) => {
      state.mode = action.payload.mode;
      state.selectedPackage = action.payload.data;
      if ((state.mode === ModalMode.Edit || state.mode === ModalMode.View) && state.selectedPackage) {
        state.packageItems = state.selectedPackage.packageItems;
      }
    },
    resetSelectedPackage: (state) => {
      state.mode = ModalMode.Unknown;
      state.packageItems = initialState.packageItems;
      state.selectedPackage = initialState.selectedPackage;
    },
    setExpandedPerformer: (state, action: PayloadAction<Option | null>) => {
      state.expandedPerformer = action.payload;
    },
  },
});

export const { addPackageItem, removePackageItem, resetSelectedPackage, setSelectedPackage, setExpandedPerformer } =
  branchBillingPackages.actions;

export default branchBillingPackages.reducer;
