import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchBillingItemState } from 'app/redux/branchBillingItem/types';
import { Option, PerformerBillingItem } from 'app/types';

const initialState: BranchBillingItemState = {
  selectedPerformerBillingItem: null,
  selectedItemParams: null,
  expandedPerformer: null,
};

const branchBillingItems = createSlice({
  name: 'branchBillingItems',
  initialState,
  reducers: {
    setSelectedPerformerBillingItem: (state, action: PayloadAction<PerformerBillingItem | null>) => {
      state.selectedPerformerBillingItem = action.payload;
    },
    setSelectedItemParams: (state, action: PayloadAction<{ branchId: string; branchBillingItemId: string }>) => {
      state.selectedItemParams = action.payload;
    },
    setExpandedPerformer: (state, action: PayloadAction<Option | null>) => {
      state.expandedPerformer = action.payload;
    },
  },
});

export const { setSelectedPerformerBillingItem, setSelectedItemParams, setExpandedPerformer } =
  branchBillingItems.actions;

export default branchBillingItems.reducer;
